import { FC } from "react";
import {
  ArrayInput,
  BooleanInput,
  CheckboxGroupInput,
  Create,
  CreateProps,
  DateTimeInput,
  NumberInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  maxLength,
  minValue,
  required,
} from "react-admin";
import TimeInput from "../shared/components/TimeInput";
import { Weekdays } from "../shared/types";

export const ExtraRewardProgramCreate: FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" validate={[required(), maxLength(100)]} />
        <BooleanInput
          source="isEnabled"
          validate={required()}
          initialValue={true}
        />
        <TextInput source="remark" multiline />
        <NumberInput
          source="creditMultiplier"
          validate={[required(), minValue(2)]}
          initialValue={2}
        />
        <NumberInput
          source="maxGiveCount"
          validate={required()}
          initialValue={999}
        />
        <DateTimeInput source="beginTime" validate={required()} />
        <DateTimeInput source="endTime" validate={required()} />
        <CheckboxGroupInput
          source="weekdays"
          parse={(input: string[]) => {
            return input?.reduce((acc: { [key: string]: boolean }, cur) => {
              acc[cur] = true;
              return acc;
            }, {});
          }}
          format={(input: Weekdays) => {
            if (!input) {
              return [];
            }
            const result: string[] = [];
            for (const [key, value] of Object.entries(input)) {
              if (value === true) {
                result.push(key);
              }
            }
            return result;
          }}
          optionText="weekday"
          optionValue="weekday"
          choices={[
            { weekday: "sunday" },
            { weekday: "monday" },
            { weekday: "tuesday" },
            { weekday: "wednesday" },
            { weekday: "thursday" },
            { weekday: "friday" },
            { weekday: "saturday" },
          ]}
        />
        <ArrayInput source="timeRanges">
          <SimpleFormIterator>
            <TimeInput
              label="resources.coupons.fields.from"
              source="from"
              validate={required()}
            />
            <TimeInput
              label="resources.coupons.fields.to"
              source="to"
              validate={required()}
            />
          </SimpleFormIterator>
        </ArrayInput>
        <ReferenceArrayInput
          source="outlets"
          reference="outlets"
          format={(outlets: any[]) => outlets && outlets.map((o: any) => o.id)}
          parse={(ids: string[]) => ids && ids.map((id: string) => ({ id }))}
        >
          <SelectArrayInput optionText="name" optionValue="id" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};
