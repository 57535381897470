import React, { FC } from "react";
import { FunctionField, Record, ReferenceField } from "react-admin";
import { Coupon } from "../types";

interface CouponReferenceFieldProps {
  addLabel?: boolean;
  source: string;
  label: string;
}

const CouponReferenceField: FC<CouponReferenceFieldProps> = (props) => (
  <ReferenceField {...props} reference="coupons">
    <FunctionField
      render={(record?: Record) => {
        if (record) {
          const coupon = record as Coupon;
          return coupon.name.default;
        } else {
          return "";
        }
      }}
    />
  </ReferenceField>
);

export default CouponReferenceField;
