import { FC } from "react";
import {
  BooleanField,
  Datagrid,
  List,
  ListProps,
  NumberField,
  TextField
} from "react-admin";

const StaffRewardSettingList: FC<ListProps> = (props) => (
  <List {...props} sort={{ field: "workingHours", order: "ASC" }}>
    <Datagrid rowClick="edit">
      <NumberField source="workingHours" />
      <TextField source="employmentType" />
      <TextField source="remark" />
      <BooleanField source="isEnabled" />
    </Datagrid>
  </List>
);

export default StaffRewardSettingList;
