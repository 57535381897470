import { CouponPromotionCreate } from "./CouponPromotionCreate";
import { CouponPromotionEdit } from "./CouponPromotionEdit";
import { CouponPromotionList } from "./CouponPromotionList";

const exported = {
  list: CouponPromotionList,
  edit: CouponPromotionEdit,
  create: CouponPromotionCreate,
};

export default exported;
