import PeopleIcon from "@material-ui/icons/People";
import CustomerEdit from "./CustomerEdit";
import CustomerList from "./CustomerList";

const exported = {
  list: CustomerList,
  edit: CustomerEdit,
  icon: PeopleIcon,
};
export default exported;
