import {
  AutocompleteInput,
  Filter,
  FunctionField,
  List,
  ListProps,
  NumberField,
  ReferenceField,
  ReferenceInput,
  TextField,
  TextInput,
} from "react-admin";

import { FC } from "react";
import { Datagrid, DateField } from "react-admin";
import CustomerReferenceField from "../shared/components/CustomerReferenceField";
import { Customer } from "../shared/types";
// @ts-ignore

const StaffRewardRecordFilter: FC<Omit<FilterProps, "children">> = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput
        label="customerTel"
        source="customer.id"
        reference="customers"
      >
        <AutocompleteInput
          optionText={(customer: Customer) =>
            customer.callingCode + customer.tel
          }
        />
      </ReferenceInput>
      <TextInput source="year" />
      <TextInput source="month" />
    </Filter>
  );
};

export const StaffRewardRecordList: FC<ListProps> = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<StaffRewardRecordFilter />}
    sort={{ field: "time", order: "DESC" }}
  >
    <Datagrid>
      <DateField source="time" showTime={true} />
      <CustomerReferenceField label="Staff" source="customer.id" />
      <TextField source="year" />
      <TextField source="month" />
      <NumberField source="workingHours" />
      <TextField source="employmentType" />
      <ReferenceField
        source="staffRewardSetting.id"
        reference="staffRewardSettings"
      >
        <FunctionField
          render={(record?: any) => {
            if (record) {
              return `Eligible reward: ${record.workingHours} working hour`;
            } else {
              return "";
            }
          }}
        />
      </ReferenceField>
      <FunctionField
        label="credit"
        render={(record: any) =>
          record?.staffRewardRecordCredit?.credit?.amount
        }
      />
      <FunctionField
        label="coupons"
        render={(record: any) =>
          record?.staffRewardRecordCouponInstances?.length
        }
      />
    </Datagrid>
  </List>
);
