import { makeStyles, TextField } from "@material-ui/core";
import { FC } from "react";
import { useInput, useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: 256,
  },
}));

const TimeInput: FC<any> = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props);
  const getLabel = () => {
    if (props.label) {
      return translate(props.label);
    } else {
      return translate(props.source);
    }
  };

  return (
    <TextField
      type="time"
      name={name}
      label={getLabel()}
      onChange={onChange}
      error={!!(touched && error)}
      helperText={touched && error}
      required={isRequired}
      className={classes.textField}
      {...rest}
    />
  );
};

export default TimeInput;
