import { makeStyles } from "@material-ui/core/styles";
import React, { ChangeEvent, FC, useState } from "react";
import { TextInput, TextInputProps, useInput } from "react-admin";
import { ChromePicker, ColorResult } from "react-color";

interface ReactColorInputState {
  show: boolean;
  hex: string;
}

const useStyles = makeStyles(() => ({
  colorPicker: {
    position: "absolute",
    zIndex: 2,
  },
  colorInputCover: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
}));

const ReactColorInput: FC<TextInputProps> = (props) => {
  const classes = useStyles();
  const {
    input: { onChange, value },
  } = useInput(props);
  const [state, setState] = useState<ReactColorInputState>({
    show: false,
    hex: value,
  });

  const isValidColorHex = (input: string) => /^#[0-9A-Fa-f]{6}$/i.test(input);
  const filterHexInput = (input: string) => {
    let hex = input;
    if (!isValidColorHex(hex)) {
      hex = "";
    }
    return hex;
  };

  const handleOpen = () =>
    setState(() => ({
      ...state,
      show: true,
    }));
  const handleClose = () =>
    setState(() => ({
      ...state,
      show: false,
    }));
  const handleColorInputChangeComplete = (color: ColorResult) => {
    handleChange(color.hex);
  };
  const handleTextInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.value);
  };
  const handleChange = (input: string) => {
    const hex = filterHexInput(input);
    onChange(hex);
    setState(() => ({
      ...state,
      hex: hex,
    }));
  };
  return (
    <div>
      <TextInput
        {...props}
        onFocus={handleOpen}
        autoComplete="off"
        onChange={handleTextInputChange}
      />
      {state.show && (
        <div className={classes.colorPicker}>
          <div className={classes.colorInputCover} onClick={handleClose} />
          <ChromePicker
            color={state.hex}
            onChangeComplete={handleColorInputChangeComplete}
            disableAlpha={true}
          />
        </div>
      )}
    </div>
  );
};

export default ReactColorInput;
