import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TierGroupCreate from "./TierGroupCreate";
import TierGroupEdit from "./TierGroupEdit";
import TierGroupList from "./TierGroupList";
const exported = {
  list: TierGroupList,
  edit: TierGroupEdit,
  create: TierGroupCreate,
  icon: TrendingUpIcon,
};

export default exported;
