import { FC } from "react";
import {
  ArrayInput,
  BooleanInput,
  Create,
  CreateProps,
  DateTimeInput,
  ImageField,
  ImageInput,
  NumberInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import MultiLanguageInput from "../shared/components/MultiLanguageInput";
import { uploadFileToAwsS3 } from "../shared/helpers";
import CouponInput from "./CouponInput";

export const CouponLinkSharingCreate: FC<CreateProps> = (props) => {
  const transform = async (data: any) => {
    if (!data.imageUrl) {
      return data;
    }
    const imageUrl = await uploadFileToAwsS3(data.imageUrl.rawFile);
    data.imageUrl = imageUrl;
    return data;
  };

  return (
    <Create {...props} transform={transform}>
      <SimpleForm>
        {MultiLanguageInput({
          label: "resources.couponLinkSharings.fields.name",
          source: "name",
          validate: required(),
        })}
        <NumberInput source="maxGiveCount" initialValue={1} />
        <TextInput source="remark" />
        <DateTimeInput source="beginTime" validate={required()} />
        <DateTimeInput source="endTime" validate={required()} />
        <BooleanInput
          source="isEnabled"
          validate={required()}
          initialValue={true}
        />
        <ImageInput source="imageUrl" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
        <ArrayInput validate={required()} source="couponLinkSharingCoupons">
          <CouponInput />
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};
