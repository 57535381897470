import React from "react";
import { TextInput, TextInputProps, useTranslate } from "react-admin";

const MultiLanguageInput = (props: TextInputProps) => {
  const translate = useTranslate();
  let labelValue: any;
  if (props.label && typeof props.label === "string") {
    labelValue = translate(props.label);
  } else {
    labelValue = props.label;
  }

  return ["enUS", "zhTW"].map((locale) => (
    <TextInput
      {...props}
      key={`${props.source}.${locale}`}
      label={`${labelValue} (${locale})`}
      source={`${props.source}.${locale}`}
      validate={props.validate}
    />
  ));
};

export default MultiLanguageInput;
