import { FC } from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Filter,
  FilterProps,
  List,
  ListProps,
  ReferenceInput,
  TextField
} from "react-admin";
import CustomerReferenceField from "../shared/components/CustomerReferenceField";
import { Credit } from "../shared/types";

const ManualCreditFilter: FC<Omit<FilterProps, "children">> = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput source="credit.id" reference="credits">
        <AutocompleteInput
          optionText={(credit: Credit) => credit?.id?.toString()}
        />
      </ReferenceInput>
    </Filter>
  );
};

const ManualCreditList: FC<ListProps> = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    sort={{ field: "credit.time", order: "DESC" }}
    filters={<ManualCreditFilter />}
  >
    <Datagrid rowClick="show">
      <DateField showTime={true} source="credit.time" />
      <CustomerReferenceField
        source="credit.customer.id"
        label="resources.customers.fields.tel"
      />
      <TextField source="remark" />
      <TextField source="reason" />
      <TextField source="outlet.name" />
      <TextField source="credit.amount" />
      <TextField source="credit.operateUser" />
    </Datagrid>
  </List>
);

export default ManualCreditList;
