import React, { FC } from "react";
import {
  ArrayInput,
  BooleanInput,
  Create,
  CreateProps,
  required,
  SimpleForm,
} from "react-admin";
import Inputs from "./Inputs";
import TelsInput from "./TelsInput";

const WelcomeOfferProgramCreate: FC<CreateProps> = (props) => (
  <Create {...props}>
    <SimpleForm>
      {Inputs}
      <BooleanInput
        source="isPermanent"
        initialValue={true}
        validate={required()}
      />
      <BooleanInput
        source="isEnabled"
        initialValue={true}
        validate={required()}
      />
      <BooleanInput
        source="isLimitToTelOnly"
        initialValue={true}
        validate={required()}
      />
      <ArrayInput source="welcomeOfferProgramTels">
        <TelsInput />
      </ArrayInput>
    </SimpleForm>
  </Create>
);

export default WelcomeOfferProgramCreate;
