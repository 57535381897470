import { FC } from "react";
import {
  Datagrid,
  List,
  ListProps,
  TextField
} from "react-admin";
import CustomerGroupActionFields from "./CustomerGroupActionFields";

const CustomerGroupActionList: FC<ListProps> = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="customerGroup.name" />
      {CustomerGroupActionFields}
    </Datagrid>
  </List>
);

export default CustomerGroupActionList;
