import React, { FC } from "react";
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  Edit,
  EditProps,
  FormTab,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  required,
  TabbedForm,
  TextField,
} from "react-admin";
import AddNewScheduleButton from "./AddNewScheduleButton";
import Inputs from "./Inputs";

const SpendingRewardProgramEdit: FC<EditProps> = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="resources.spendingRewardPrograms.fieldGroups.config">
          {Inputs}
          <BooleanInput source="isConditional" validate={required()} />
          <BooleanInput
            source="isLimitToCreditEarnedTransaction"
            validate={required()}
          />
        </FormTab>
        <FormTab
          label="resources.spendingRewardPrograms.fieldGroups.schedules"
          path="schedules"
        >
          <ReferenceManyField
            sort={{ field: "beginTime", order: "ASC" }}
            label=""
            reference="spendingRewardProgramSchedules"
            target="spendingRewardProgram.id"
            perPage={5}
            pagination={<Pagination />}
          >
            <Datagrid rowClick="edit">
              <DateField showTime={true} source="beginTime" />
              <DateField showTime={true} source="endTime" />
              <BooleanField source="isEnabled" />
            </Datagrid>
          </ReferenceManyField>
          <AddNewScheduleButton />
        </FormTab>
        <FormTab
          label="resources.spendingRewardPrograms.fieldGroups.records"
          path="records"
        >
          <ReferenceManyField
            sort={{ field: "credit.time", order: "DESC" }}
            label=""
            reference="spendingRewardRecords"
            target="spendingRewardProgramSchedule.spendingRewardProgram.id"
            perPage={5}
            pagination={<Pagination />}
          >
            <Datagrid>
              <DateField source="credit.time" showTime={true} />
              <NumberField source="credit.amount" />
              <ReferenceField
                source="transaction.id"
                reference="transactions"
                link="show"
              >
                <TextField source="id" />
              </ReferenceField>
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default SpendingRewardProgramEdit;
