import { FC } from "react";
import {
  AutocompleteArrayInput,
  BooleanInput,
  Edit,
  EditProps,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import { Customer } from "../shared/types";

const FranchiseStoreEdit: FC<EditProps> = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <BooleanInput source="isEnabled" validate={required()} />
      <TextInput source="businessRegistrationNumber" />
      <TextInput source="address" />
      <TextInput source="remark" />
      <ReferenceArrayInput
        source="managementMembers"
        reference="customers"
        format={(customers: any[]) =>
          customers && customers.map((o: any) => o.id)
        }
        parse={(ids: string[]) => ids && ids.map((id: string) => ({ id }))}
        filterToQuery={(searchText: string) =>
          searchText ? { tel: searchText } : ""
        }
      >
        <AutocompleteArrayInput
          optionText={(customer: Customer) =>
            `+${customer?.callingCode}${customer?.tel} ${customer?.name}`
          }
          optionValue="id"
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

export default FranchiseStoreEdit;
