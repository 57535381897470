import { FC } from "react";
import {
  AutocompleteArrayInput,
  Create,
  CreateProps,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import { Customer } from "../shared/types";

const CustomerGroupCreate: FC<CreateProps> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="remark" />
      <ReferenceArrayInput
        source="customers"
        reference="customers"
        format={(customers: any[]) =>
          customers && customers.map((o: any) => o.id)
        }
        parse={(ids: string[]) => ids && ids.map((id: string) => ({ id }))}
        filterToQuery={(searchText: string) =>
          searchText ? { tel: searchText } : ""
        }
      >
        <AutocompleteArrayInput
          optionText={(customer: Customer) =>
            `+${customer?.callingCode}${customer?.tel} ${customer?.name}`
          }
          optionValue="id"
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

export default CustomerGroupCreate;
