import { ExtraRewardProgramCreate } from "./ExtraRewardProgramCreate";
import { ExtraRewardProgramEdit } from "./ExtraRewardProgramEdit";
import { ExtraRewardProgramList } from "./ExtraRewardProgramList";

const exported = {
  list: ExtraRewardProgramList,
  edit: ExtraRewardProgramEdit,
  create: ExtraRewardProgramCreate,
};

export default exported;
