import React from "react";
import { maxLength, NumberInput, required, TextInput } from "react-admin";

const Inputs = [
  <TextInput source="name" validate={[required(), maxLength(100)]} />,
  <TextInput source="description" multiline={true} />,
  <NumberInput source="amount" validate={required()} />,
];

export default Inputs;
