import { FC } from "react";
import {
  ArrayInput,
  BooleanInput,
  Edit,
  EditProps,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
// @ts-ignore
import CouponInput from "./CouponInput";

export const StaffRewardSettingEdit: FC<EditProps> = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <NumberInput source="workingHours" validate={required()} />
        <SelectInput
          source="employmentType"
          choices={[
            {
              id: "fullTime",
              name: "resources.staffRewardSettings.employmentType.fullTime",
            },
            {
              id: "partTime",
              name: "resources.staffRewardSettings.employmentType.partTime",
            },
          ]}
        />
        <TextInput source="remark" />
        <BooleanInput source="isEnabled" validate={required()} />
        <NumberInput source="creditAmount" validate={required()} />
        <ArrayInput source="staffRewardSettingCoupons">
          <CouponInput />
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};
