import ReceiptIcon from "@material-ui/icons/Receipt";
import { TransactionList } from "./TransactionList";
import { TransactionShow } from "./TransactionShow";

const exported = {
  list: TransactionList,
  show: TransactionShow,
  icon: ReceiptIcon,
  // Disable manual transaction creation as we allow customer to record transaction themselves
  // create: TransactionCreate,
};
export default exported;
