import MoneyIcon from "@material-ui/icons/Money";
import React, { FC } from "react";
import { Button, Record } from "react-admin";
import { Link } from "react-router-dom";

interface AddNewCreditEarningThresholdButtonProps {
  record?: Record;
}

const AddNewCreditEarningThresholdButton: FC<AddNewCreditEarningThresholdButtonProps> =
  ({ record }) => (
    <Button
      component={Link}
      to={`/creditEarningThresholdMappings/create?tier.id=${record?.id}`}
      label="resources.tiers.createCreditEarningThreshold"
    >
      <MoneyIcon />
    </Button>
  );

export default AddNewCreditEarningThresholdButton;
