import { DateField, TextField } from "react-admin";

const ManualCouponFields = [
  <DateField
    key="couponInstance.issueTime"
    showTime={true}
    source="couponInstance.issueTime"
  />,
  <TextField
    key="couponInstance.coupon.name.default"
    source="couponInstance.coupon.name.default"
  />,
  <TextField
    key="couponInstance.issuedByUser"
    source="couponInstance.issuedByUser"
  />,
  <TextField key="remark" source="remark" />,
  <TextField source="reason" />,
  <TextField source="outlet.name" />,
];

export default ManualCouponFields;
