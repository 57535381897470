import React, { FC } from "react";
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  ListProps,
  NumberField,
  SingleFieldList,
  TextField,
} from "react-admin";
import CouponReferenceField from "../shared/components/CouponReferenceField";

const CustomerRewardProgramList: FC<ListProps> = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="type" />
      <NumberField source="amount" />
      <CouponReferenceField
        label="resources.couponInstances.fields.coupon.name"
        source="coupon.id"
      />
      <DateField source="beginTime" />
      <DateField source="endTime" />
      <BooleanField source="isPermanent" />
      <BooleanField source="isEnabled" />
      <ArrayField source="tiers">
        <SingleFieldList>
          <FunctionField
            render={(tier: any) =>
              `${tier?.tierGroup?.name || ""} ${tier?.name?.default}`
            }
          />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </List>
);
export default CustomerRewardProgramList;
