import React from "react";
import { DateField, FunctionField, NumberField, Record } from "react-admin";
import { SpendingRewardRecord, Transaction } from "../shared/types";

const TransactionFields = [
  <DateField key="time" source="time" showTime={true} />,
  <NumberField
    key="totalAmount"
    source="totalAmount"
    options={{
      minimumFractionDigits: 2,
      style: "currency",
      currency: "HKD",
    }}
  />,
  <NumberField
    key="spentAmount"
    source="spentAmount"
    options={{
      minimumFractionDigits: 2,
      style: "currency",
      currency: "HKD",
    }}
  />,
  <NumberField
    key="credit.amount"
    label="resources.transactions.fields.earnedCredit"
    source="credit.amount"
    options={{ minimumFractionDigits: 2 }}
    emptyText="0.00"
  />,
  <NumberField
    key="creditConsumption.amount"
    label="resources.transactions.fields.consumedCredit"
    source="creditConsumption.amount"
    options={{ minimumFractionDigits: 2 }}
    emptyText="0.00"
  />,
  <FunctionField
    key="spendingReward"
    label="resources.transactions.fields.spendingReward"
    render={(record?: Record) =>
      (record
        ? (record as Transaction).spendingRewardRecords
            .map((r: SpendingRewardRecord) => r.credit.amount)
            .reduce((acc: number, cur: number) => cur + acc, 0)
        : 0
      ).toLocaleString("en-US", { minimumFractionDigits: 2 })
    }
  />,
];

export default TransactionFields;
