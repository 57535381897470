import React, { FC } from "react";
import { Show, ShowProps, SimpleShowLayout } from "react-admin";
import CustomerReferenceField from "../shared/components/CustomerReferenceField";
import ManualCouponFields from "./ManualCouponFields";

export const ManualCouponShow: FC<ShowProps> = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <CustomerReferenceField
        addLabel={true}
        source="couponInstance.customer.id"
        label="resources.customers.fields.tel"
      />
      {ManualCouponFields}
    </SimpleShowLayout>
  </Show>
);
