import { FC } from "react";
import {
  BooleanInput,
  Create,
  CreateProps,
  required,
  SimpleForm,
} from "react-admin";
import Inputs from "./Inputs";

const SpendingRewardProgramCreate: FC<CreateProps> = (props) => (
  <Create {...props}>
    <SimpleForm>
      {Inputs}
      <BooleanInput
        initialValue={true}
        source="isConditional"
        validate={required()}
      />
      <BooleanInput
        initialValue={true}
        source="isLimitToCreditEarnedTransaction"
        validate={required()}
      />
    </SimpleForm>
  </Create>
);

export default SpendingRewardProgramCreate;
