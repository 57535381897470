import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import CustomerRewardProgramCreate from "./CustomerRewardProgramCreate";
import CustomerRewardProgramEdit from "./CustomerRewardProgramEdit";
import CustomerRewardProgramList from "./CustomerRewardProgramList";
const exported = {
  list: CustomerRewardProgramList,
  edit: CustomerRewardProgramEdit,
  create: CustomerRewardProgramCreate,
  icon: CardGiftcardIcon,
};

export default exported;
