import { FC } from "react";
import { Datagrid, List, ListProps, TextField } from "react-admin";

export const SystemConfigurationList: FC<ListProps> = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="value" />
    </Datagrid>
  </List>
);
