import FranchiseStoreCreate from "./FranchiseStoreCreate";
import FranchiseStoreEdit from "./FranchiseStoreEdit";
import FranchiseStoreList from "./FranchiseStoreList";

const exported = {
  list: FranchiseStoreList,
  edit: FranchiseStoreEdit,
  create: FranchiseStoreCreate,
};
export default exported;
