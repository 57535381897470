import { FC } from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  ImageField,
  List,
  ListProps,
  NumberField,
  TextField
} from "react-admin";
import MultiLanguageField from "../shared/components/MultiLanguageField";

export const CouponLinkSharingList: FC<ListProps> = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      {MultiLanguageField({
        label: "resources.couponLinkSharings.fields.name",
        source: "name",
      })}
      <NumberField source="maxGiveCount" />
      <TextField source="remark" />
      <DateField source="beginTime" showTime={true} />
      <DateField source="endTime"  showTime={true} />
      <BooleanField source="isEnabled" />
      <ImageField source="imageUrl" />
    </Datagrid>
  </List>
);
