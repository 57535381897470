import { FC } from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Filter,
  FilterProps,
  List,
  ListProps,
  ReferenceInput,
  TextField
} from "react-admin";
import CustomerReferenceField from "../shared/components/CustomerReferenceField";
import { CreditConsumption } from "../shared/types";

const ManualCreditConsumptionFilter: FC<Omit<FilterProps, "children">> = (
  props
) => {
  return (
    <Filter {...props}>
      <ReferenceInput
        source="creditConsumption.id"
        reference="creditConsumptions"
      >
        <AutocompleteInput
          optionText={(creditConsumption: CreditConsumption) =>
            creditConsumption?.id?.toString()
          }
        />
      </ReferenceInput>
    </Filter>
  );
};

const ManualCreditConsumptionList: FC<ListProps> = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    sort={{ field: "creditConsumption.time", order: "DESC" }}
    filters={<ManualCreditConsumptionFilter />}
  >
    <Datagrid rowClick="show">
      <DateField showTime={true} source="creditConsumption.time" />
      <CustomerReferenceField
        source="creditConsumption.credits[0].customer.id"
        label="resources.customers.fields.tel"
      />
      <TextField source="remark" />
      <TextField source="reason" />
      <TextField source="outlet.name" />
      <TextField source="creditConsumption.amount" />
      <TextField source="creditConsumption.operateUser" />
    </Datagrid>
  </List>
);

export default ManualCreditConsumptionList;
