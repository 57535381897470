import { DateField, FunctionField, NumberField, TextField } from "react-admin";

const CustomerGroupActionFields = [
  <DateField key="time" source="time" showTime={true} />,
  <TextField key="remark" source="remark" />,
  <NumberField key="creditAmount" source="creditAmount" />,
  <FunctionField
    key="issuedCouponsCount"
    label="resources.customerGroupActions.fields.issuedCouponsCount"
    render={(record: any) =>
      record?.issuedCoupons
        ?.map((c: any) => c.quantity)
        .reduce((a: number, b: number) => a + b, 0) ?? 0
    }
  />,
  <TextField key="operateUser" source="operateUser" />,
];

export default CustomerGroupActionFields;
