import { FC } from "react";
import {
  Create,
  CreateProps,
  minValue,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
} from "react-admin";
import MultiLanguageInput from "../shared/components/MultiLanguageInput";
import ReactColorInput from "../shared/components/ReactColorInput";

const TierCreate: FC<CreateProps> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        label="resources.tierGroups.name"
        source="tierGroup.id"
        reference="tierGroups"
        allowEmpty
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      {MultiLanguageInput({
        label: "resources.tiers.fields.name",
        source: "name",
        validate: required(),
      })}
      <ReactColorInput source="colorCode" validate={required()} />
      <NumberInput
        source="creditEarningThreshold"
        validate={[required(), minValue(1)]}
      />
      <NumberInput
        source="spendingThreshold"
        validate={[required(), minValue(1)]}
      />
      <NumberInput
        source="creditExpiryDays"
        validate={[required(), minValue(1)]}
      />
    </SimpleForm>
  </Create>
);

export default TierCreate;
