import { makeStyles } from "@material-ui/styles";
import React, { FC } from "react";
import { SimpleFormIterator, TextInput } from "react-admin";

const useStyles = makeStyles(() => ({
  telInputGroup: {
    "& li div.ra-input": {
      display: "inline-block",
      marginRight: 10,
    },
  },
}));

const TelsInput: FC<any> = (props) => {
  const classes = useStyles();
  return (
    <SimpleFormIterator
      margin="dense"
      className={classes.telInputGroup}
      {...props}
    >
      <TextInput source="callingCode" />
      <TextInput source="tel" />
    </SimpleFormIterator>
  );
};

export default TelsInput;
