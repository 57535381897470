import { Card, CardContent, CardHeader } from "@material-ui/core";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import packageJson from "../../package.json";
import { AppState } from "../shared/types";

const Dashboard: FC = () => {
  const backendConfigs = useSelector((state: AppState) => state.backendConfigs);
  return (
    <Card>
      <CardHeader title="Biteos CRM" />
      <CardContent>
        v{packageJson.version} / v{backendConfigs.version}
      </CardContent>
    </Card>
  );
};

export default Dashboard;
