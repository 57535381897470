import { FC } from "react";
import { BooleanField, Datagrid, DateField, List, ListProps, NumberField, TextField } from "react-admin";

export const ExtraRewardProgramList: FC<ListProps> = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <BooleanField source="isEnabled" />
      <TextField source="remark" />
      <NumberField source="creditMultiplier" />
      <NumberField source="maxGiveCount" />
      <DateField source="beginTime" />
      <DateField source="endTime" />
    </Datagrid>
  </List>
);
