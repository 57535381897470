import { FC } from "react";
import {
  ArrayInput,
  BooleanInput,
  CheckboxGroupInput,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  EditProps,
  FormTab,
  NumberInput,
  Pagination,
  ReferenceArrayInput,
  ReferenceManyField,
  SelectArrayInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  maxLength,
  minValue,
  required,
} from "react-admin";
import CustomerReferenceField from "../shared/components/CustomerReferenceField";
import TimeInput from "../shared/components/TimeInput";
import { Weekdays } from "../shared/types";

export const ExtraRewardProgramEdit: FC<EditProps> = (props) => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="Configuration">
        <TextInput source="name" validate={[required(), maxLength(100)]} />
        <BooleanInput source="isEnabled" validate={required()} />
        <TextInput source="remark" multiline />
        <NumberInput
          source="creditMultiplier"
          validate={[required(), minValue(2)]}
        />
        <NumberInput source="maxGiveCount" validate={required()} />
        <DateTimeInput source="beginTime" validate={required()} />
        <DateTimeInput source="endTime" validate={required()} />
        <CheckboxGroupInput
          source="weekdays"
          parse={(input: string[]) => {
            return input?.reduce((acc: { [key: string]: boolean }, cur) => {
              acc[cur] = true;
              return acc;
            }, {});
          }}
          format={(input: Weekdays) => {
            if (!input) {
              return [];
            }
            const result: string[] = [];
            for (const [key, value] of Object.entries(input)) {
              if (value === true) {
                result.push(key);
              }
            }
            return result;
          }}
          optionText="weekday"
          optionValue="weekday"
          choices={[
            { weekday: "sunday" },
            { weekday: "monday" },
            { weekday: "tuesday" },
            { weekday: "wednesday" },
            { weekday: "thursday" },
            { weekday: "friday" },
            { weekday: "saturday" },
          ]}
        />
        <ArrayInput source="timeRanges">
          <SimpleFormIterator>
            <TimeInput
              label="resources.coupons.fields.from"
              source="from"
              validate={required()}
            />
            <TimeInput
              label="resources.coupons.fields.to"
              source="to"
              validate={required()}
            />
          </SimpleFormIterator>
        </ArrayInput>
        <ReferenceArrayInput
          source="outlets"
          reference="outlets"
          format={(outlets: any[]) => outlets && outlets.map((o: any) => o.id)}
          parse={(ids: string[]) => ids && ids.map((id: string) => ({ id }))}
        >
          <SelectArrayInput optionText="name" optionValue="id" />
        </ReferenceArrayInput>
      </FormTab>
      <FormTab label="Histories" path="history">
        <ReferenceManyField
          sort={{ field: "time", order: "DESC" }}
          label=""
          reference="extraRewardProgramRecords"
          target="extraRewardProgram.id"
          perPage={5}
          pagination={<Pagination />}
        >
          <Datagrid>
            <DateField source="time" showTime={true} />
            <CustomerReferenceField
              label="customer"
              source="customer.id"
            />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  </Edit>
);
