import { TranslationMessages } from "ra-core";
import englishMessages from "ra-language-english";

const customEnglishMessages: TranslationMessages = {
  ...englishMessages,
  Unauthorized: "Unauthorized",
  resources: {
    customers: {
      name: "Customers",
      fields: {
        id: "ID",
        name: "Name",
        gender: "Gender",
        callingCode: "Calling Code",
        tel: "Customer Tel",
        email: "Email",
        receivePromotionalMessage: "Receive promotional message?",
        birthday: "Birthday",
        ageRange: "Age range",
        birthYear: "Birth year",
        birthMonth: "Birth month",
        isEnabled: "Enabled",
        tier: "Tier",
        createdAt: "Created At",
        createNewManualCoupon: "Issue Coupon",
        couponDetails: "Issue details",
        createNewTransaction: "Create Transaction",
      },
      fieldGroups: {
        information: "information",
        credit: "Credit",
        available: "Available",
        consumed: "Consumed",
        expired: "Expired",
        creditSummary: "Credit Summary",
        creditHistory: "Credit History",
        amount: "Amount",
        addCredit: "Add Credit",
        deductCredit: "Deduct Credit",
        detail: "Detail",
      },
      messages: {
        creditNotEnough: "No enough credit to deduct",
        failedCreditModification:
          "Failed to add/deduct credit, please contact IT",
      },
    },
    tierGroups: {
      name: "Tier Group",
      fields: {
        id: "ID",
        name: "Name",
        effectiveTime: "Effective Time",
      },
    },
    tiers: {
      name: "Tier",
      creditEarningThresholds: "Credit earning thresholds",
      createCreditEarningThreshold: "Create credit earning thresholds",
      fields: {
        id: "ID",
        name: "Name",
        creditEarningThreshold: "Credit earning threshold",
        spendingThreshold: "Spending threshold",
        brand: "Brand",
        creditExpiryDays: "Credit expiry days",
      },
    },
    transactions: {
      name: "Transactions",
      fields: {
        time: "Time",
        customerTel: "Customer Tel",
        totalAmount: "Total amount",
        spentAmount: "Spent amount",
        refId: "Reference #",
        refApp: "Reference system",
        earnedCredit: "Earned credit",
        consumedCredit: "Consumed credit",
        spendingReward: "Spending reward",
        redeemedCoupon: "Redeemed coupon",
        "refContent.outletId": "Outlet",
      },
      fieldGroups: {
        summary: "Summary",
      },
    },
    customerRewardPrograms: {
      name: "Customer Reward Programs",
      fields: {
        name: "Name",
        type: "Type",
        amount: "Amount",
        coupon: "Coupon",
        tiers: "Tiers",
        isPermanent: "Permanent",
        isEnabled: "Enabled",
        customerTel: "Customer Tel",
        "config.periodicInterval": "Interval",
        time: "Time",
      },
      fieldGroups: {
        daily: "Daily",
        weekly: "Weekly",
        monthly: "Monthly",
        yearly: "Yearly",
        config: "Configuration",
        histories: "Histories",
        records: "Records",
      },
    },
    customerRewardHistories: {
      name: "Customer Reward Histories",
      fields: {
        time: "Time",
        amount: "Amount",
        "customerRewardProgram.tiers": "Tiers",
      },
    },
    spendingRewardPrograms: {
      name: "Spending Reward Programs",
      fields: {
        name: "Name",
        amount: "Amount",
        isConditional: "Conditional?",
        isLimitToCreditEarnedTransaction: "Limit to credit earned transaction?",
      },
      fieldGroups: {
        config: "Configuration",
        schedules: "Schedules",
        records: "Records",
        creditSchedule: "Create Schedule",
        "transaction.id": "Transaction",
      },
    },
    spendingRewardProgramSchedules: {
      name: "Spending Reward Program Schedules",
      fields: {
        beginTime: "Begin Time",
        endTime: "End Time",
        isEnabled: "Enabled?",
        "spendingRewardProgram.name": "Spending Reward Program",
        "spendingRewardProgram.id": "Spending Reward Program",
      },
    },
    spendingRewardRecords: {
      name: "Spending Reward Records",
      fields: {
        time: "Time",
        "credit.amount": "Amount",
        "transaction.id": "Transaction",
      },
    },
    welcomeOfferPrograms: {
      name: "Welcome Offer Programs",
      fields: {
        id: "ID",
        name: "Name",
        amount: "Amount",
        beginTime: "Begin Time",
        endTime: "End Time",
        isPermanent: "Permanent",
        isEnabled: "Enabled",
        isLimitToTelOnly: "Limit to Tel Only",
        welcomeOfferProgramTels: "Tels",
        callingCode: "Calling Code",
        tel: "Tel",
        time: "Time",
        customerTel: "Customer Tel",
      },
      fieldGroups: {
        config: "Configuration",
        records: "Records",
      },
    },
    manualCredits: {
      name: "Manual Credits",
      fields: {
        id: "ID",
        remark: "Remark",
        reason: "Reason",
        outlet: "Outlet",
        "outlet.id": "Outlet",
        "outlet.name": "Outlet",
        "credit.amount": "Amount",
        "credit.time": "Time",
        "credit.operateUser": "Operated by",
      },
      reasons: {
        vip: "VIP",
        compliment: "Compliment/讚賞",
        complain: "Complain/投訴",
        compensation: "Compensation/賠償",
        makeUpPoint: "Make up points/補分",
      },
    },
    manualCreditConsumptions: {
      name: "Manual Credit Consumptions",
      fields: {
        id: "ID",
        remark: "Remark",
        reason: "Reason",
        outlet: "Outlet",
        "outlet.id": "Outlet",
        "outlet.name": "Outlet",
        "creditConsumption.time": "Time",
        "creditConsumption.amount": "Amount",
        "creditConsumption.operateUser": "Operated by",
      },
    },
    coupons: {
      name: "Coupons",
      fields: {
        name: "Name",
        description: "Description",
        remark: "Remark",
        validFrom: "Valid from",
        type: "Type",
        expiryType: "Expiry type",
        expiryDate: "Expiry date",
        validDaysAfterIssue: "Valid days after issue",
        "config.amount": "Amount",
        usePeriodWeekdaysSelections: "Use period weekdays",
        usePeriodTimeRanges: "Use period time ranges",
        from: "From",
        to: "To",
        useOutlets: "Use outlets",
        minSpend: "Min. spend",
      },
      helpers: {
        expiryDate: "Select time as 23:59 (11:59 pm) to make it valid until the end of the day",
      }
    },
    couponPromotions: {
      name: "Coupon Promotions",
      fields: {
        name: "Name",
        remark: "Remark",
        beginTime: "Begin time",
        endTime: "End time",
        isEnabled: "Enabled",
        "coupon.name": "Coupon",
        "coupon.id": "Coupon",
        customer: "Customer",
        "couponInstance.issueTime": "Time",
        quantity: "Quantity",
        couponPromotionCoupons: "Coupons",
        imageUrl: "Image",
      },
      fieldGroups: {
        config: "Configuration",
        records: "Records",
      },
    },
    couponInstances: {
      name: "Coupons",
      fields: {
        isRedeemed: "Redeemed?",
        issuedByUser: "Issued by user",
        issueTime: "Issue time",
        issueType: "Issue type",
        redeemedByUser: "Redeemed by user",
        redeemTime: "Redeem time",
        isCancelled: "Cancelled?",
        cancelTime: "Cancel time",
        cancel: "Cancel",
        confirm: "Confirm",
        cancelCoupon: "Cancel coupon",
        cancelCouponDesc: "Are you sure you want to cancel this coupon?",

        type: "Coupon type",
        "config.amount": "Amount",
        expiryDate: "Expiry date",
        "coupon.name": "Coupon",
      },
    },
    manualCoupons: {
      name: "Manual Coupons",
      fields: {
        "couponInstance.issueTime": "Time",
        "couponInstance.coupon.name.default": "Coupon",
        "couponInstance.issuedByUser": "Issued by",
        remark: "Remark",
        reason: "Reason",
        outlet: "Outlet",
        "outlet.id": "Outlet",
        "outlet.name": "Outlet",
      },
    },
    groupBuyingGroups: {
      name: "Group Buying Groups",
      fields: {
        name: "Name",
        isPrivateGroup: "Private?",
        imageUrl: "Group image",
        leader: "Leader",
      },
      fieldGroups: {
        group: "Group",
        events: "Events",
      },
    },
    groupBuyingEvents: {
      name: "Group Buying Events",
      fields: {
        "groupBuyingGroup.id": "Group",
        pickUpPointCount: "Pick up point(s)",
        name: "Name",
        "outlet.name": "Brand (Outlet)",
        "outlet.id": "Brand (Outlet)",
        status: "Status",
        launchTime: "Launch time",
        deliveryDate: "Delivery date",
        groupBuyingPickUpPoints: "Pick up points",
        "groupBuyingDistrict.name": "District",
        time: "Time",
        inChargePersonName: "In charge person name",
        inChargePersonMobile: "In charge person mobile",
        "groupBuyingDistrict.id": "District",
        minAmount: "Min amount",
        driverLicensePlate: "Driver license plate",
        driverMobile: "Driver mobile",
        remark: "Remarks",
      },
      fieldGroups: {
        event: "Event",
        pickUpPoints: "Pick up points",
        createPickUpPoint: "Create pick up point",
      },
    },
    groupBuyingPickUpPoints: {
      name: "Group buying pick up point",
      fields: {
        "groupBuyingEvent.id": "Event",
        name: "Name",
        time: "Time",
        inChargePersonName: "In charge person name",
        inChargePersonMobile: "In charge person mobile",
        imageUrl: "Pick up point image",
      },
    },
    franchiseStores: {
      name: "Co-branding Shops",
      fields: {
        name: "Name",
        isEnabled: "Enabled",
        remark: "Remark",
        managementMembers: "Management Members",
        managementMembersCount: "Management Members Amount",
        businessRegistrationNumber: "Business Registration Number",
        address: "Address",
      },
    },
    customerGroups: {
      name: "Customer Groups",
      fields: {
        name: "Name",
        remark: "Remark",
        customerCount: "Customers amount",
        createNewAction: "Create new group action",
      },
      fieldGroups: {
        information: "Information",
        actions: "Actions",
      },
    },
    customerGroupActions: {
      name: "Customer Group Actions",
      fields: {
        time: "Time",
        "customerGroup.name": "Customer Group",
        remark: "Remark",
        creditAmount: "Credit Amount",
        issuedCouponsCount: "Issued Coupons Amount",
        couponId: "Coupon",
        quantity: "Quantity",
      },
    },
    couponLinkSharings: {
      name: "Coupon Link Sharing",
      fields: {
        name: "name",
        maxGiveCount: "Max number of times to giveaway",
        remark: "Remark",
        beginTime: "Begin time",
        endTime: "End time",
        isEnabled: "Enabled",
        couponLinkSharingCoupons: "Coupons",
        "coupon.id": "Coupon",
        quantity: "Quantity",
        customer: "Customer",
        imageUrl: "Image",
      },
      fieldGroups: {
        config: "Configuration",
        histories: "Histories",
        shareLink: "Share link",
        copy: "Copy to clipboard",
      },
    },
    systemConfigurations: {
      name: "System Configurations",
      fields: {
        id: "Name",
        value: "Value",
      },
    },
    extraRewardPrograms: {
      name: "Extra Reward Programs",
    },
    staffRewardSettings: {
      name: "Staff Reward Settings",
      fields: {
        isEnabled: "Enabled",
        staffRewardSettingCoupons: "Coupons",
        "coupon.id": "Coupon",
        quantity: "Quantity",
      },
      employmentType: {
        fullTime: "Full Time",
        partTime: "Part Time",
      },
    },
    staffRewardRecords: {
      name: "Staff Reward Records",
      fields: {
        time: "Issue time",
        "staffRewardSetting.id": "Eligible reward",
      },
    },
  },
};

export default customEnglishMessages;
