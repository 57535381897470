import { FC } from "react";
import {
  AutocompleteArrayInput,
  Datagrid,
  Edit,
  EditProps,
  FormTab,
  Pagination,
  ReferenceArrayInput,
  ReferenceManyField,
  TabbedForm,
  TextInput,
  required
} from "react-admin";
import CustomerGroupActionFields from "../customer-group-actions/CustomerGroupActionFields";
import { Customer } from "../shared/types";
import AddNewActionButton from "./AddNewActionButton";

const CustomerGroupEdit: FC<EditProps> = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="resources.customerGroups.fieldGroups.information">
          <TextInput source="name" validate={required()} />
          <TextInput source="remark" />
          <ReferenceArrayInput
            source="customers"
            reference="customers"
            format={(customers: any[]) =>
              customers && customers.map((o: any) => o.id)
            }
            parse={(ids: string[]) => ids && ids.map((id: string) => ({ id }))}
            filterToQuery={(searchText: string) =>
              searchText ? { tel: searchText } : ""
            }
          >
            <AutocompleteArrayInput
              optionText={(customer: Customer) =>
                `+${customer?.callingCode}${customer?.tel} ${customer?.name}`
              }
              optionValue="id"
            />
          </ReferenceArrayInput>
        </FormTab>
        <FormTab label="resources.customerGroups.fieldGroups.actions" path="actions">
          <ReferenceManyField
            sort={{ field: "time", order: "DESC" }}
            label=""
            reference="customerGroupActions"
            target="customerGroup.id"
            perPage={5}
            pagination={<Pagination />}
          >
            <Datagrid rowClick="show">{CustomerGroupActionFields}</Datagrid>
          </ReferenceManyField>
          <AddNewActionButton />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default CustomerGroupEdit;
