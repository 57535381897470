import { FC } from "react";
import {
  Datagrid,
  FunctionField,
  List,
  ListProps,
  TextField,
} from "react-admin";

const CustomerGroupList: FC<ListProps> = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="remark" />
      <FunctionField
        label="resources.customerGroups.fields.customerCount"
        render={(record: any) => record?.customers?.length ?? 0}
      />
    </Datagrid>
  </List>
);

export default CustomerGroupList;
