import React, { FC } from "react";
import { FunctionField, Record, ReferenceField } from "react-admin";
import { Customer } from "../types";

interface CustomerReferenceFieldProps {
  addLabel?: boolean;
  source: string;
  label: string;
}

const CustomerReferenceField: FC<CustomerReferenceFieldProps> = (props) => (
  <ReferenceField {...props} reference="customers">
    <FunctionField
      render={(record?: Record) => {
        if (record) {
          const customer = record as Customer;
          return `+${(customer as Customer).callingCode} ${customer.tel}`;
        } else {
          return "";
        }
      }}
    />
  </ReferenceField>
);

export default CustomerReferenceField;
