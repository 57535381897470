import { FC } from "react";
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  List,
  ListProps,
  NumberField,
  SingleFieldList,
  TextField,
} from "react-admin";
import GroupBuyingGroupReferenceField from "../shared/components/GroupBuyingGroupReferenceField";

export const GroupBuyingEventList: FC<ListProps> = (props) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="outlet.name" />
      <TextField source="status" />
      <DateField showTime={true} source="launchTime" />
      <DateField source="deliveryDate" />
      <NumberField source="minAmount" />
      <ArrayField source="groupBuyingPickUpPoints" sortable={false}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <GroupBuyingGroupReferenceField
        source="groupBuyingGroup.id"
        label="resources.groupBuyingGroups.name"
      />
      <TextField source="groupBuyingDistrict.name" />
    </Datagrid>
  </List>
);
