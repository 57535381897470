import { StaffRewardSettingCreate } from "./StaffRewardSettingCreate";
import { StaffRewardSettingEdit } from "./StaffRewardSettingEdit";
import StaffRewardSettingList from "./StaffRewardSettingList";

const exported = {
  list: StaffRewardSettingList,
  edit: StaffRewardSettingEdit,
  create: StaffRewardSettingCreate,
};
export default exported;
