import {
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
} from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import lightBlue from "@material-ui/core/colors/lightBlue";
import red from "@material-ui/core/colors/red";
import { makeStyles } from "@material-ui/core/styles";
import AddBoxIcon from "@material-ui/icons/AddBox";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import dayjs from "dayjs";
import { FC, useCallback, useEffect, useState } from "react";
import {
  Identifier,
  RecordMap,
  useGetList,
  useTranslate,
  useVersion,
} from "react-admin";
import { Link } from "react-router-dom";
import { CREDITS_QUERY } from "../shared/api-path";
import { datetimeFormat } from "../shared/constants/constant-values";
import { CreditConsumptionType } from "../shared/constants/credit-consumption-type.enum";
import { CreditType } from "../shared/constants/credit-type.enum";
import { basicFetch } from "../shared/http-base";
import {
  AsideProps,
  CreditConsumption as CreditConsumptionRecord,
  Credit as CreditRecord,
} from "../shared/types";

interface CreditHistoryItemProps {
  history: CreditHistoryRecord;
}
interface CreditStatisticState {
  available: number;
  consumed: number;
  expired: number;
}
interface CreditHistoryRecord {
  id: Identifier;
  time: Date;
  category: CreditHistoryCategory;
  type: CreditType | CreditConsumptionType;
  amount: number;
  operateUser: string;
}

enum CreditHistoryCategory {
  Earning,
  Consumption,
}

const useStyles = makeStyles(() => ({
  root: {
    width: 400,
    marginLeft: 10,
  },
  title: {
    marginBottom: 15,
  },
  creditStatisticWrapper: {
    marginBottom: 10,
  },
  creditStatisticContent: {
    textAlign: "center",
  },
  available: {
    color: green[700],
  },
  consumed: {
    color: red[900],
  },
  expired: {
    color: lightBlue[900],
  },
  creditModificationWrapper: {
    marginTop: 15,
  },
  creditModificationForm: {
    width: 300,
    height: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    top: ``,
  },
  creditHistoryItem: {
    marginBottom: 5,
  },
}));

const CustomerCredit: FC<AsideProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CreditStatistic {...props} />
      <CreditHistory {...props} />
    </div>
  );
};

const CreditStatistic: FC<AsideProps> = (props) => {
  const { record } = props;
  const classes = useStyles();
  const translate = useTranslate();
  const version = useVersion();
  const [state, setState] = useState<CreditStatisticState>({
    available: 0,
    consumed: 0,
    expired: 0,
  });
  const fetchCreditStatistic = useCallback(async () => {
    if (!record) {
      return;
    }
    const response = await basicFetch(
      CREDITS_QUERY.replace("{customerId}", record.id.toString()),
      {
        method: "GET",
      }
    );
    const responseBody = await response.json();
    setState((state) => ({
      ...state,
      available: responseBody.available,
      consumed: responseBody.consumed,
      expired: responseBody.expired,
    }));
  }, [record]);

  useEffect(() => {
    fetchCreditStatistic();
  }, [fetchCreditStatistic, version]);

  return (
    <Card className={classes.creditStatisticWrapper}>
      <CardContent>
        <Typography variant="h5" className={classes.title}>
          {translate("resources.customers.fieldGroups.creditSummary")}
        </Typography>
        <Grid
          container
          spacing={1}
          justify="center"
          alignItems="center"
          className={classes.creditStatisticContent}
        >
          <Grid item xs={4}>
            <Typography variant="h6" className={classes.available}>
              {state.available.toLocaleString("en-US", {
                minimumFractionDigits: 2,
              })}
            </Typography>
            <Typography color="textSecondary">
              {translate("resources.customers.fieldGroups.available")}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" className={classes.consumed}>
              {state.consumed.toLocaleString("en-US", {
                minimumFractionDigits: 2,
              })}
            </Typography>
            <Typography color="textSecondary">
              {translate("resources.customers.fieldGroups.consumed")}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" className={classes.expired}>
              {state.expired.toLocaleString("en-US", {
                minimumFractionDigits: 2,
              })}
            </Typography>
            <Typography color="textSecondary">
              {translate("resources.customers.fieldGroups.expired")}
            </Typography>
          </Grid>
        </Grid>
        <CreditModification {...props} />
      </CardContent>
    </Card>
  );
};

const CreditModification: FC<AsideProps> = ({ record }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div className={classes.creditModificationWrapper}>
      <Grid
        container
        spacing={1}
        justify="center"
        alignItems="center"
        className={classes.creditStatisticContent}
      >
        <Grid item xs={6}>
          <Button
            component={Link}
            to={`/manualCredits/create?customer.id=${record?.id}`}
            variant="contained"
            color="primary"
            size="small"
          >
            {translate("resources.customers.fieldGroups.addCredit")}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            component={Link}
            to={`/manualCreditConsumptions/create?customer.id=${record?.id}`}
            variant="contained"
            color="secondary"
            size="small"
          >
            {translate("resources.customers.fieldGroups.deductCredit")}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const CreditHistory: FC<AsideProps> = ({ record }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { data: credits, ids: creditIds } = useGetList<CreditRecord>(
    "credits",
    { page: 1, perPage: 10 },
    { field: "time", order: "DESC" },
    record && { "customer.id": record.id }
  );
  const { data: creditConsumptions, ids: creditConsumptionIds } =
    useGetList<CreditConsumptionRecord>(
      "creditConsumptions",
      { page: 1, perPage: 10 },
      { field: "time", order: "DESC" },
      record && { "credits.customer.id": record.id }
    );
  const creditHistories = mixEarningAndConsumption(
    credits,
    creditIds,
    creditConsumptions,
    creditConsumptionIds
  );

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" className={classes.title}>
          {translate("resources.customers.fieldGroups.creditHistory")}
        </Typography>
        <div>
          {creditHistories.map((history) => (
            <CreditHistoryItem key={history.id} history={history} />
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

const mixEarningAndConsumption = (
  credits?: RecordMap<CreditRecord>,
  creditIds?: Identifier[],
  creditConsumptions?: RecordMap<CreditConsumptionRecord>,
  creditConsumptionIds?: Identifier[]
): CreditHistoryRecord[] => {
  const earningHistories =
    credits && creditIds
      ? creditIds.map<CreditHistoryRecord>((id) => ({
          id: id,
          time: credits[id].time,
          category: CreditHistoryCategory.Earning,
          type: credits[id].type,
          amount: credits[id].amount,
          operateUser: credits[id].operateUser,
        }))
      : [];
  const consumptionHistories =
    creditConsumptions && creditConsumptionIds
      ? creditConsumptionIds.map<CreditHistoryRecord>((id) => ({
          id: id,
          time: creditConsumptions[id].time,
          category: CreditHistoryCategory.Consumption,
          type: creditConsumptions[id].type,
          amount: -creditConsumptions[id].amount,
          operateUser: creditConsumptions[id].operateUser,
        }))
      : [];
  const creditHistories = earningHistories.concat(consumptionHistories);
  return creditHistories
    .sort((h1, h2) => dayjs(h2.time).unix() - dayjs(h1.time).unix())
    .slice(0, 10);
};

const CreditHistoryItem: FC<CreditHistoryItemProps> = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const history = props.history;

  const additionalInfo = (history: CreditHistoryRecord) => {
    if (
      history.type === CreditType.Manual ||
      history.type === CreditConsumptionType.Manual
    ) {
      const filterContent =
        history.category === CreditHistoryCategory.Earning
          ? {
              credit: {
                id: history.id,
              },
            }
          : {
              creditConsumption: {
                id: history.id,
              },
            };
      const path =
        history.category === CreditHistoryCategory.Earning
          ? "/manualCredits"
          : "/manualCreditConsumptions";
      return (
        <Button
          size="small"
          color="primary"
          component={Link}
          to={{
            pathname: path,
            search: `filter=${encodeURIComponent(
              JSON.stringify(filterContent)
            )}`,
          }}
        >
          {translate("resources.customers.fieldGroups.detail")}
        </Button>
      );
    } else if (
      history.type === CreditType.EarnByTransactionRecord
    ) {
      const filterContent =
        history.category === CreditHistoryCategory.Earning
          ? {
              credit: {
                id: history.id,
              },
            }
          : {
              creditConsumption: {
                id: history.id,
              },
            };
      return (
        <Button
          size="small"
          color="primary"
          component={Link}
          to={{
            pathname: "/transactions",
            search: `filter=${encodeURIComponent(
              JSON.stringify(filterContent)
            )}`,
          }}
        >
          {translate("resources.customers.fieldGroups.detail")}
        </Button>
      );
    } else {
      return <span></span>;
    }
  };
  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      spacing={2}
      className={classes.creditHistoryItem}
    >
      <Grid item>
        {history.category === CreditHistoryCategory.Earning ? (
          <AddBoxIcon style={{ color: green[500] }} />
        ) : (
          <IndeterminateCheckBoxIcon style={{ color: red[500] }} />
        )}
      </Grid>
      <Grid item xs container>
        <Grid item xs container direction="column" spacing={2}>
          <Grid item xs>
            <Typography variant="subtitle1" gutterBottom>
              {dayjs(history.time).format(datetimeFormat)}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {history.amount.toLocaleString("en-US", {
                minimumFractionDigits: 2,
              })}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {additionalInfo(history)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Chip variant="outlined" size="small" label={history.type} />
      </Grid>
    </Grid>
  );
};

export default CustomerCredit;
