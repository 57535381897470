import React from "react";
import {
  DateInput,
  maxLength,
  NumberInput,
  required,
  TextInput,
} from "react-admin";

const Inputs = [
  <TextInput
    key="name"
    source="name"
    validate={[required(), maxLength(100)]}
  />,
  <NumberInput key="amount" source="amount" validate={required()} />,
  <DateInput key="beginTime" source="beginTime" />,
  <DateInput key="endTime" source="endTime" />,
];

export default Inputs;
