import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FC } from "react";
import {
  ArrayInput,
  BooleanInput,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  EditProps,
  FormTab,
  ImageInput,
  NumberInput,
  Pagination,
  ReferenceManyField,
  TabbedForm,
  TextInput,
  required,
  useTranslate,
} from "react-admin";
// @ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";
import CustomerReferenceField from "../shared/components/CustomerReferenceField";
import MultiLanguageInput from "../shared/components/MultiLanguageInput";
import PreviewImage from "../shared/components/PreviewImage";
import { uploadFileToAwsS3 } from "../shared/helpers";
import { AsideProps } from "../shared/types";
import CouponInput from "./CouponInput";

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 25,
    paddingTop: 25,
    paddingBottom: 25,
  },
}));

const LinkArea: FC<AsideProps> = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { record } = props;
  return (
    <div className={classes.root}>
      <h2>{translate("resources.couponLinkSharings.fieldGroups.shareLink")}</h2>
      <TextField
        disabled
        value={`${process.env.REACT_APP_CSO_URL}?couponLinkSharingId=${record?.id}`}
        multiline={true}
      />
      <div>
        <CopyToClipboard
          text={`${process.env.REACT_APP_CSO_URL}?couponLinkSharingId=${record?.id}`}
        >
          <Button variant="contained">
            {translate("resources.couponLinkSharings.fieldGroups.copy")}
          </Button>
        </CopyToClipboard>
      </div>
    </div>
  );
};

export const CouponLinkSharingEdit: FC<EditProps> = (props) => {
  const transform = async (data: any) => {
    if (!data.imageUrl) {
      return data;
    }
    // If the image is a string, it means that the image has not been changed
    // Otherwise, the image has been changed, we need to upload the image to AWS S3
    if (typeof data.imageUrl !== "string") {
      const imageUrl = await uploadFileToAwsS3(data.imageUrl.rawFile);
      data.imageUrl = imageUrl;
    }
    return data;
  };
  return (
    <Edit {...props} aside={<LinkArea />} transform={transform}>
      <TabbedForm>
        <FormTab label="resources.couponLinkSharings.fieldGroups.config">
          {MultiLanguageInput({
            label: "resources.couponLinkSharings.fields.name",
            source: "name",
            validate: required(),
          })}
          <NumberInput source="maxGiveCount" />
          <TextInput source="remark" />
          <DateTimeInput source="beginTime" validate={required()} />
          <DateTimeInput source="endTime" validate={required()} />
          <BooleanInput source="isEnabled" validate={required()} />
          <ImageInput
            source="imageUrl"
            accept="image/*
        "
          >
            <PreviewImage source="src" />
          </ImageInput>
          <ArrayInput validate={required()} source="couponLinkSharingCoupons">
            <CouponInput />
          </ArrayInput>
        </FormTab>
        <FormTab
          label="resources.couponLinkSharings.fieldGroups.histories"
          path="history"
        >
          <ReferenceManyField
            sort={{ field: "time", order: "DESC" }}
            label=""
            reference="couponLinkSharingHistories"
            target="couponLinkSharing.id"
            perPage={5}
            pagination={<Pagination />}
          >
            <Datagrid>
              <DateField source="time" showTime={true} />
              <CustomerReferenceField
                label="resources.couponLinkSharings.fields.customer"
                source="customer.id"
              />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
