import { CreateProps } from "ra-core/esm/controller/details/useCreateController";
import React, { FC } from "react";
import {
  Create,
  DateTimeInput,
  required,
  SimpleForm,
  TextInput,
} from "react-admin";

const TierGroupCreate: FC<CreateProps> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <DateTimeInput source="effectiveTime" validate={required()} />
    </SimpleForm>
  </Create>
);

export default TierGroupCreate;
