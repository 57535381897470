import { parse } from "query-string";
import React, { FC } from "react";
import {
  DateInput,
  Edit,
  EditProps,
  ImageField,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export const GroupBuyingPickUpPointEdit: FC<EditProps> = (props) => {
  const { "groupBuyingEvent.id": groupBuyingEventId } = parse(
    props.location?.search || ""
  );
  const redirect = groupBuyingEventId
    ? `/groupBuyingEvents/${groupBuyingEventId}/pickUpPoint`
    : "/groupBuyingEvents";

  return (
    <Edit {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput
          source="groupBuyingEvent.id"
          reference="groupBuyingEvents"
          validate={required()}
        >
          <SelectInput disabled={true} optionValue="id" optionText="name" />
        </ReferenceInput>
        <ImageField source="imageUrl" />
        <TextInput source="name" validate={required()} />
        <DateInput source="time" validate={required()} />
        <TextInput source="inChargePersonName" validate={required()} />
        <TextInput source="inChargePersonMobile" validate={required()} />
      </SimpleForm>
    </Edit>
  );
};
