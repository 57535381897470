import { FC } from "react";
import {
  BooleanField,
  Datagrid,
  ImageField,
  List,
  ListProps,
  TextField,
} from "react-admin";
import CustomerReferenceField from "../shared/components/CustomerReferenceField";

export const GroupBuyingGroupList: FC<ListProps> = (props) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <BooleanField source="isPrivateGroup" />
      <ImageField source="imageUrl" />
      <CustomerReferenceField
        source="leader.id"
        label="resources.groupBuyingGroups.fields.leader"
      />
    </Datagrid>
  </List>
);
