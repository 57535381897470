import { ThemeOptions } from "@material-ui/core";
import crudProvider from "ra-data-nestjsx-crud";
import { Admin, InitialState, Resource, defaultTheme } from "react-admin";
import { authProvider } from "./auth/authProvider";
import couponLinkSharing from "./coupon-link-sharing";
import couponPromotions from "./coupon-promotions";
import coupons from "./coupons";
import CreditEarningThresholdMappings from "./credit-earning-threshold-mapping";
import customerGroupActions from "./customer-group-actions";
import customerGroups from "./customer-groups";
import customerRewardPrograms from "./customer-reward-programs";
import customers from "./customers";
import { Dashboard } from "./dashboard";
import extraRewardPrograms from "./extra-reward-programs";
import franchiseStores from "./franchise-stores";
import groupBuyingEvents from "./group-buying-events";
import groupBuyingGroups from "./group-buying-groups";
import groupBuyingPickUpPoints from "./group-buying-pick-up-points";
import i18nProvider from "./i18n/i18nProvider";
import manualCoupons from "./manual-coupons";
import manualCreditConsumptions from "./manual-credit-consumptions";
import manualCredits from "./manual-credits";
import backendConfigsReducer from "./redux/backendConfigsReducer";
import { basicHttpClient } from "./shared/http-base";
import spendingRewardProgramSchedules from "./spending-reward-program-schedules";
import spendingRewardPrograms from "./spending-reward-programs";
import staffRewardRecords from "./staff-reward-records";
import staffRewardSettings from "./staff-reward-settings";
import systemConfigurations from "./system-configurations";
import tierGroups from "./tier-groups";
import tiers from "./tiers";
import transactions from "./transactions";
import welcomeOfferPrograms from "./welcome-offer-programs";

export interface CustomThemeOptions extends ThemeOptions {
  sidebar?: {
    width?: number;
  };
  menu?: {
    width?: number;
  };
}

const dataProvider = crudProvider(
  process.env.REACT_APP_BACKEND_URL || "",
  basicHttpClient
);

const theme: CustomThemeOptions = {
  ...defaultTheme,
  sidebar: {
    width: 300,
  },
  menu: {
    width: 300,
  },
};

function App(props: { initialState: InitialState | undefined }) {
  return (
    <Admin
      initialState={props.initialState}
      customReducers={{ backendConfigs: backendConfigsReducer }}
      dataProvider={dataProvider}
      authProvider={authProvider}
      dashboard={Dashboard}
      i18nProvider={i18nProvider}
      theme={theme}
    >
      {(permissions) => [
        <Resource name="staffRewardSettings" {...staffRewardSettings} />,
        <Resource name="staffRewardRecords" {...staffRewardRecords} />,
        <Resource name="extraRewardPrograms" {...extraRewardPrograms} />,
        <Resource name="franchiseStores" {...franchiseStores} />,
        <Resource name="coupons" {...coupons} />,
        <Resource name="couponLinkSharings" {...couponLinkSharing} />,
        <Resource name="couponPromotions" {...couponPromotions} />,
        <Resource name="customers" {...customers} />,
        <Resource name="customerGroups" {...customerGroups} />,
        <Resource name="customerGroupActions" {...customerGroupActions} />,
        <Resource name="tierGroups" {...tierGroups} />,
        <Resource name="tiers" {...tiers} />,
        <Resource name="transactions" {...transactions} />,
        <Resource name="customerRewardPrograms" {...customerRewardPrograms} />,
        <Resource name="spendingRewardPrograms" {...spendingRewardPrograms} />,
        <Resource
          name="spendingRewardProgramSchedules"
          {...spendingRewardProgramSchedules}
        />,
        <Resource name="welcomeOfferPrograms" {...welcomeOfferPrograms} />,
        <Resource
          name="creditEarningThresholdMappings"
          {...CreditEarningThresholdMappings}
        />,
        <Resource name="manualCredits" {...manualCredits} />,
        <Resource
          name="manualCreditConsumptions"
          {...manualCreditConsumptions}
        />,
        <Resource name="manualCoupons" {...manualCoupons} />,
        <Resource name="groupBuyingGroups" {...groupBuyingGroups} />,
        <Resource name="groupBuyingEvents" {...groupBuyingEvents} />,
        <Resource
          name="groupBuyingPickUpPoints"
          {...groupBuyingPickUpPoints}
        />,
        <Resource name="systemConfigurations" {...systemConfigurations} />,
        <Resource name="credits" />,
        <Resource name="creditConsumptions" />,
        <Resource name="customerRewardHistories" />,
        <Resource name="customerRewardHistoryRecords" />,
        <Resource name="spendingRewardRecords" />,
        <Resource name="welcomeOfferRecords" />,
        <Resource name="brands" />,
        <Resource name="couponInstances" />,
        <Resource name="groupBuyingDistricts" />,
        <Resource name="outlets" />,
        <Resource name="couponPromotionRecords" />,
        <Resource name="couponLinkSharingHistories" />,
        <Resource name="extraRewardProgramRecords" />,
      ]}
    </Admin>
  );
}

export default App;
