import React, { FC } from "react";
import {
  BooleanField,
  Datagrid,
  List,
  ListProps,
  NumberField,
  TextField,
} from "react-admin";

const SpendingRewardProgramList: FC<ListProps> = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <NumberField source="amount" />
      <BooleanField source="isConditional" />
      <BooleanField source="isLimitToCreditEarnedTransaction" />
    </Datagrid>
  </List>
);
export default SpendingRewardProgramList;
