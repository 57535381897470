import { ImageField } from "ra-ui-materialui";

const PreviewImage = ({ record, source }: any) => {
  if (typeof record == "string") {
    record = {
      [source]: record,
    };
  }
  return <ImageField record={record} source={source} />;
};

export default PreviewImage;
