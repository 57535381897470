export const LOGIN: string = "/auth/login";
export const LOGOUT: string = "/auth/logout";
export const REFRESH_TOKEN: string = "/auth/refresh";

export const CREDITS_QUERY: string = "/credits/query/{customerId}";

export const BACKEND_CONFIGS: string = "/system/configs";

export const GET_S3_PUT_OBJECT_SIGNED_URL: string =
  "/authCustomer/awsS3/getPutObjectSignedUrl/";
