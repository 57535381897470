import LocalActivity from "@material-ui/icons/LocalActivity";
import CouponCreate from "./CouponCreate";
import CouponEdit from "./CouponEdit";
import CouponList from "./CouponList";

const exported = {
  list: CouponList,
  edit: CouponEdit,
  create: CouponCreate,
  icon: LocalActivity,
};

export default exported;
