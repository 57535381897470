import React, { FC } from "react";
import {
  ArrayField,
  BooleanInput,
  ChipField,
  Datagrid,
  DateField,
  Edit,
  EditProps,
  FormTab,
  ImageField,
  Pagination,
  ReferenceInput,
  ReferenceManyField,
  required,
  SaveButton,
  SelectInput,
  SingleFieldList,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
} from "react-admin";
import { Customer } from "../shared/types";

// A custom toolbar to remove the delete button
const CustomToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const GroupBuyingGroupEdit: FC<EditProps> = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm toolbar={<CustomToolbar />}>
        <FormTab label="resources.groupBuyingGroups.fieldGroups.group">
          <ImageField source="imageUrl" />
          <TextInput source="name" validate={required()} />
          <BooleanInput source="isPrivateGroup" validate={required()} />
          <ReferenceInput
            source="leader.id"
            reference="customers"
            label="resources.groupBuyingGroups.fields.leader"
            validate={required()}
          >
            <SelectInput
              disabled={true}
              optionText={(customer: Customer) =>
                `+${customer.callingCode} ${customer.tel}`
              }
              optionValue="id"
            />
          </ReferenceInput>
        </FormTab>
        <FormTab label="resources.groupBuyingGroups.fieldGroups.events">
          <ReferenceManyField
            sort={{ field: "launchTime", order: "DESC" }}
            label=""
            reference="groupBuyingEvents"
            target="groupBuyingGroup.id"
            perPage={5}
            pagination={<Pagination />}
          >
            <Datagrid rowClick="edit">
              <TextField source="name" />
              <TextField source="status" />
              <DateField showTime={true} source="launchTime" />
              <DateField source="deliveryDate" />
              <ArrayField source="groupBuyingPickUpPoints">
                <SingleFieldList>
                  <ChipField source="name" />
                </SingleFieldList>
              </ArrayField>
              <TextField source="groupBuyingDistrict.name" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
