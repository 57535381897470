import CustomerGroupActionCreate from "./CustomerGroupActionCreate";
import CustomerGroupActionList from "./CustomerGroupActionList";
import { CustomerGroupActionShow } from "./CustomerGroupActionShow";

const exported = {
  list: CustomerGroupActionList,
  show: CustomerGroupActionShow,
  create: CustomerGroupActionCreate,
};

export default exported;
