import { FC } from "react";
import {
  BooleanField, Datagrid,
  DateField,
  ImageField,
  List,
  ListProps, TextField
} from "react-admin";
import MultiLanguageField from "../shared/components/MultiLanguageField";

export const CouponPromotionList: FC<ListProps> = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      {MultiLanguageField({
        label: "resources.couponPromotions.fields.name",
        source: "name",
      })}
      <TextField source="remark" />
      <DateField source="beginTime" />
      <DateField source="endTime" />
      <BooleanField source="isEnabled" />
      <ImageField source="imageUrl" />
    </Datagrid>
  </List>
);
