import React from "react";
import { TextField, TextFieldProps, useTranslate } from "react-admin";

const MultiLanguageField = (props: TextFieldProps) => {
  const translate = useTranslate();
  let labelValue: any;
  if (props.label && typeof props.label === "string") {
    labelValue = translate(props.label);
  } else {
    labelValue = props.label;
  }

  return ["enUS", "zhTW"].map((locale) => (
    <TextField
      {...props}
      key={`${props.source}.${locale}`}
      label={`${labelValue} (${locale})`}
      source={`${props.source}.${locale}`}
      sortable={false}
    />
  ));
};

export default MultiLanguageField;
