import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import React, { FC } from "react";
import {
  BooleanInput,
  Button,
  Datagrid,
  DateField,
  Edit,
  EditProps,
  FormTab,
  FunctionField,
  NumberInput,
  Pagination,
  ReferenceInput,
  ReferenceManyField,
  SaveButton,
  SelectInput,
  TabbedForm,
  TextInput,
  Toolbar,
  fetchEnd,
  fetchStart,
  required,
  useRefresh,
  useTranslate,
} from "react-admin";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import CouponReferenceField from "../shared/components/CouponReferenceField";
import { CouponIssueType } from "../shared/constants/coupon-issue-type.enum";
import { basicFetch } from "../shared/http-base";
import { CouponInstance } from "../shared/types";
import TransactionFields from "../transactions/TransactionFields";
import AddNewManualCouponButton from "./AddNewManualCouponButton";
import AddNewTransactionButton from "./AddNewTransactionButton";
import CustomerCredit from "./CustomerCredit";
import ageRangeOptions from "./ageRangeOptions.json";

const CustomerEdit: FC<EditProps> = (props) => {
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const translate = useTranslate();
  const [cancellingCouponInstanceId, setCancellingCouponInstanceId] =
    React.useState<string | null>(null);
  const [openCancelDialog, setOpenCancelDialog] = React.useState(false);
  const [cancelRemark, setCancelRemark] = React.useState("");
  const handleOpenCancelDialog = (couponInstanceId: string) => {
    setOpenCancelDialog(true);
    setCancellingCouponInstanceId(couponInstanceId);
    setCancelRemark("");
  };
  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
    setCancellingCouponInstanceId(null);
    setCancelRemark("");
  };
  const handleCancelCoupon = async () => {
    dispatch(fetchStart());
    try {
      await basicFetch("/couponInstances/cancel", {
        method: "POST",
        body: JSON.stringify({
          id: cancellingCouponInstanceId,
          remark: cancelRemark,
        }),
      });
    } finally {
      dispatch(fetchEnd());
      handleCloseCancelDialog();
      refresh();
    }
  };

  return (
    <Edit {...props} aside={<CustomerCredit />}>
      <TabbedForm toolbar={<CustomToolbar />}>
        <FormTab label="resources.customers.fieldGroups.information">
          <TextInput disabled source="callingCode" validate={required()} />
          <TextInput disabled source="tel" validate={required()} />
          <TextInput source="name" />
          <SelectInput
            source="gender"
            choices={[
              {
                id: "male",
                name: "male",
              },
              {
                id: "female",
                name: "female",
              },
              {
                id: "other",
                name: "other",
              },
            ]}
          />
          <TextInput source="email" />
          <BooleanInput source="receivePromotionalMessage" />
          <SelectInput source="ageRange" choices={ageRangeOptions} />
          {/* <NumberInput source="birthYear" min={1} /> */}
          <NumberInput source="birthMonth" min={1} max={12} />
          <BooleanInput source="isEnabled" />
          <ReferenceInput
            disabled
            label="Tier"
            source="tier.id"
            reference="tiers"
          >
            <SelectInput
              optionText={(tier: any) =>
                `${tier.tierGroup?.name || ""} ${tier.name.default}`
              }
              optionValue="id"
            />
          </ReferenceInput>
        </FormTab>
        <FormTab label="resources.transactions.name" path="transactions">
          <ReferenceManyField
            sort={{ field: "time", order: "DESC" }}
            label=""
            reference="transactions"
            target="customer.id"
            perPage={5}
            pagination={<Pagination />}
          >
            <Datagrid rowClick="show">{TransactionFields}</Datagrid>
          </ReferenceManyField>
          <AddNewTransactionButton />
        </FormTab>
        <FormTab label="resources.couponInstances.name" path="couponInstances">
          <ReferenceManyField
            sort={{ field: "issueTime", order: "DESC" }}
            label=""
            reference="couponInstances"
            target="customer.id"
            perPage={5}
            pagination={<Pagination />}
          >
            <Datagrid>
              <CouponReferenceField
                label="resources.couponInstances.fields.coupon.name"
                source="coupon.id"
              />
              <DateField source="issueTime" showTime={true} />
              <DateField source="validFrom" showTime={true} />
              <DateField source="expiryDate" showTime={true} />
              <FunctionField
                label="resources.couponInstances.fields.issueType"
                render={(record: any) => {
                  if (
                    (record as CouponInstance).issueType ===
                    CouponIssueType.Manual
                  ) {
                    return (
                      <Button
                        component={Link}
                        to={{
                          pathname: "/manualCoupons",
                          search: `filter=${encodeURIComponent(
                            JSON.stringify({
                              couponInstance: {
                                id: record.id,
                              },
                            })
                          )}`,
                        }}
                        label={(record as CouponInstance).issueType}
                      />
                    );
                  } else {
                    return <span>{(record as CouponInstance).issueType}</span>;
                  }
                }}
              />
              {/* <BooleanField source="isRedeemed" /> */}
              <DateField source="redeemTime" showTime={true} />
              {/* <BooleanField source="isCancelled" /> */}
              <DateField source="cancelTime" showTime={true} />
              <FunctionField
                label="resources.couponInstances.fields.cancel"
                render={(record: any) =>
                  record.isCancelled ? (
                    <></>
                  ) : (
                    <div>
                      <Button
                        onClick={() => handleOpenCancelDialog(record.id)}
                        label="resources.couponInstances.fields.cancel"
                      ></Button>
                    </div>
                  )
                }
              />
            </Datagrid>
          </ReferenceManyField>
          <AddNewManualCouponButton />
          <Dialog
            fullWidth
            open={openCancelDialog}
            onClose={handleCloseCancelDialog}
          >
            <DialogTitle>
              {translate("resources.couponInstances.fields.cancelCoupon")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {translate("resources.couponInstances.fields.cancelCouponDesc")}
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                label="Remark"
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setCancelRemark(event.target.value)}
                value={cancelRemark}
              />
              <DialogActions>
                <Button
                  onClick={handleCancelCoupon}
                  label="resources.couponInstances.fields.confirm"
                />
              </DialogActions>
            </DialogContent>
          </Dialog>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

// A custom toolbar to remove the delete button
const CustomToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export default CustomerEdit;
