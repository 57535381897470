import Add from "@material-ui/icons/Add";
import { FC } from "react";
import { Button, Record } from "react-admin";
import { Link } from "react-router-dom";

interface AddNewActionButtonProps {
  record?: Record;
}

const AddNewActionButton: FC<AddNewActionButtonProps> = ({ record }) => (
  <Button
    component={Link}
    to={`/customerGroupActions/create?customerGroup.id=${record?.id}`}
    label="resources.customerGroups.fields.createNewAction"
  >
    <Add />
  </Button>
);
export default AddNewActionButton;
