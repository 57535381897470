import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "./enUS";

const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === "zhTW") {
    return import("./zhTW").then((messages) => messages.default);
  }
  return englishMessages;
}, "enUS");

export default i18nProvider;
