import React, { FC } from "react";
import {
  BooleanInput,
  Create,
  CreateProps,
  FormDataConsumer,
  ReferenceArrayInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
} from "react-admin";
import { CustomerRewardProgramType } from "../shared/constants/customer-reward-program-type.enum";
import ConfigFields from "./ConfigsFields";
import Inputs from "./Inputs";
import { tiersFormat, tiersParse } from "./options";

const CustomerRewardProgramCreate: FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        {Inputs}
        <BooleanInput
          key="isPermanent"
          source="isPermanent"
          initialValue={true}
          validate={required()}
        />
        <BooleanInput
          key="isEnabled"
          source="isEnabled"
          initialValue={true}
          validate={required()}
        />
        <ReferenceArrayInput
          source="tiers"
          reference="tiers"
          validate={required()}
          format={tiersFormat}
          parse={tiersParse}
        >
          <SelectArrayInput
            optionText={(tier: any) =>
              `${tier.tierGroup?.name || ""} ${tier.name.default}`
            }
          />
        </ReferenceArrayInput>
        <SelectInput
          source="type"
          validate={required()}
          choices={Object.values(CustomerRewardProgramType).map((value) => {
            return {
              id: value,
              name: value,
            };
          })}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <ConfigFields type={formData.type} {...rest} />
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default CustomerRewardProgramCreate;
