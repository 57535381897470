import React, { FC } from "react";
import {
  ArrayField,
  BooleanInput,
  ChipField,
  Datagrid,
  DateField,
  Edit,
  EditProps,
  FormDataConsumer,
  FormTab,
  NumberField,
  Pagination,
  ReferenceArrayInput,
  ReferenceManyField,
  required,
  SelectArrayInput,
  SelectInput,
  SingleFieldList,
  TabbedForm,
} from "react-admin";
import CouponReferenceField from "../shared/components/CouponReferenceField";
import CustomerReferenceField from "../shared/components/CustomerReferenceField";
import { CustomerRewardProgramType } from "../shared/constants/customer-reward-program-type.enum";
import ConfigFields from "./ConfigsFields";
import Inputs from "./Inputs";
import { tiersFormat, tiersParse } from "./options";

const CustomerRewardProgramEdit: FC<EditProps> = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="resources.customerRewardPrograms.fieldGroups.config">
          {Inputs}
          <BooleanInput
            key="isPermanent"
            source="isPermanent"
            validate={required()}
          />
          <BooleanInput
            key="isEnabled"
            source="isEnabled"
            validate={required()}
          />
          <ReferenceArrayInput
            source="tiers"
            reference="tiers"
            validate={required()}
            format={tiersFormat}
            parse={tiersParse}
          >
            <SelectArrayInput
              optionText={(tier: any) =>
                `${tier.tierGroup?.name || ""} ${tier.name.default}`
              }
              optionValue="id"
            />
          </ReferenceArrayInput>
          <SelectInput
            disabled
            validate={required()}
            source="type"
            multiple={true}
            choices={Object.values(CustomerRewardProgramType).map((value) => {
              return {
                id: value,
                name: value,
              };
            })}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <ConfigFields type={formData.type} {...rest} />
            )}
          </FormDataConsumer>
        </FormTab>
        <FormTab label="resources.customerRewardPrograms.fieldGroups.histories">
          <ReferenceManyField
            sort={{ field: "time", order: "DESC" }}
            label=""
            reference="customerRewardHistories"
            target="customerRewardProgram.id"
            perPage={5}
            pagination={<Pagination />}
          >
            <Datagrid>
              <DateField source="time" showTime={true} />
              <NumberField source="amount" />
              <CouponReferenceField
                label="resources.coupons.name"
                source="couponId"
              />
              <ArrayField source="tiers">
                <SingleFieldList>
                  <ChipField source="name.default" />
                </SingleFieldList>
              </ArrayField>
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="resources.customerRewardPrograms.fieldGroups.records">
          <ReferenceManyField
            sort={{ field: "credit.time", order: "DESC" }}
            label=""
            reference="customerRewardHistoryRecords"
            target="customerRewardHistory.customerRewardProgram.id"
            perPage={5}
            pagination={<Pagination />}
          >
            <Datagrid>
              <DateField
                label="resources.customerRewardPrograms.fields.time"
                source="credit.time"
                showTime={true}
              />
              <NumberField
                label="resources.customerRewardPrograms.fields.amount"
                source="credit.amount"
              />
              <CouponReferenceField
                label="resources.couponInstances.fields.coupon.name"
                source="couponInstance.couponId"
              />
              <CustomerReferenceField
                label="resources.customerRewardPrograms.fields.customerTel"
                source="credit.customer.id"
              />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default CustomerRewardProgramEdit;
