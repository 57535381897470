import { parse } from "query-string";
import { FC } from "react";
import {
  Create,
  CreateProps,
  DateTimeInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export const GroupBuyingPickUpPointCreate: FC<CreateProps> = (props) => {
  const { "groupBuyingEvent.id": groupBuyingEventId } = parse(
    props.location?.search || ""
  );
  const redirect = groupBuyingEventId
    ? `/groupBuyingEvents/${groupBuyingEventId}/pickUpPoint`
    : "/groupBuyingEvents";

  return (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput
          source="groupBuyingEvent.id"
          reference="groupBuyingEvents"
          validate={required()}
          initialValue={groupBuyingEventId}
        >
          <SelectInput optionValue="id" optionText="name" />
        </ReferenceInput>
        <TextInput source="name" validate={required()} />
        <DateTimeInput source="time" validate={required()} />
        <TextInput source="inChargePersonName" validate={required()} />
        <TextInput source="inChargePersonMobile" validate={required()} />
      </SimpleForm>
    </Create>
  );
};
