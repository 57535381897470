import React, { FC } from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  ListProps,
  NumberField,
  TextField,
} from "react-admin";

const WelcomeOfferProgramList: FC<ListProps> = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <NumberField source="amount" />
      <DateField source="beginTime" />
      <DateField source="endTime" />
      <BooleanField source="isPermanent" />
      <BooleanField source="isEnabled" />
      <BooleanField source="isLimitToTelOnly" />
    </Datagrid>
  </List>
);

export default WelcomeOfferProgramList;
