import { CouponLinkSharingCreate } from "./CouponLinkSharingCreate";
import { CouponLinkSharingEdit } from "./CouponLinkSharingEdit";
import { CouponLinkSharingList } from "./CouponLinkSharingList";

const exported = {
  list: CouponLinkSharingList,
  edit: CouponLinkSharingEdit,
  create: CouponLinkSharingCreate,
};

export default exported;
