import ManualCouponCreate from "./ManaulCouponCreate";
import ManualCouponList from "./ManualCouponList";
import { ManualCouponShow } from "./ManualCouponShow";

const exported = {
  list: ManualCouponList,
  show: ManualCouponShow,
  create: ManualCouponCreate,
};

export default exported;
