import { FC } from "react";
import {
  Datagrid,
  List,
  ListProps,
  NumberField,
  ReferenceField,
  TextField,
} from "react-admin";
// @ts-ignore
import { ColorField } from "react-admin-color-input";
import MultiLanguageField from "../shared/components/MultiLanguageField";

const TierList: FC<ListProps> = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <ReferenceField
        label="resources.tierGroups.name"
        source="tierGroup.id"
        reference="tierGroups"
      >
        <TextField source="name" />
      </ReferenceField>
      {MultiLanguageField({
        label: "resources.tiers.fields.name",
        source: "name",
      })}
      <ColorField source="colorCode" />
      <NumberField
        source="creditEarningThreshold"
        options={{
          minimumFractionDigits: 2,
          style: "currency",
          currency: "HKD",
        }}
      />
      <NumberField
        source="spendingThreshold"
        options={{
          minimumFractionDigits: 2,
          style: "currency",
          currency: "HKD",
        }}
      />
      <NumberField source="creditExpiryDays" />
    </Datagrid>
  </List>
);

export default TierList;
