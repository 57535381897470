import { FC } from "react";
import { Datagrid, DateField, List, ListProps, TextField } from "react-admin";
import MultiLanguageField from "../shared/components/MultiLanguageField";

const CouponList: FC<ListProps> = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      {MultiLanguageField({
        label: "resources.coupons.fields.name",
        source: "name",
      })}
      {MultiLanguageField({
        label: "resources.coupons.fields.description",
        source: "description",
      })}
      <TextField source="type" />
      <TextField source="remark" />
      <DateField source="validFrom" showTime={true} />
      <TextField source="expiryType" />
    </Datagrid>
  </List>
);
export default CouponList;
