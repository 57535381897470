import { parse } from "query-string";
import { FC } from "react";
import {
  ArrayInput,
  Create,
  CreateProps,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  minValue,
  required,
} from "react-admin";
import CouponInput from "./CouponInput";

const CustomerGroupActionCreate: FC<CreateProps> = (props) => {
  const { "customerGroup.id": customerGroupId } = parse(
    props.location?.search || ""
  );
  const redirect = customerGroupId
    ? `/customerGroups/${customerGroupId}/actions`
    : `/customerGroupActions`;
  return (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput
          source="customerGroupId"
          reference="customerGroups"
          validate={required()}
          initialValue={customerGroupId}
        >
          <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <TextInput source="remark" multiline />
        <NumberInput source="creditAmount" validate={[minValue(1)]} />
        <ArrayInput source="coupons">
          <CouponInput />
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default CustomerGroupActionCreate;
