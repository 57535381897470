import React from "react";
import {
  BooleanField,
  DateField,
  EmailField,
  FunctionField,
  TextField,
} from "react-admin";
import ageRangeOptions from "./ageRangeOptions.json";

const CustomerFields = [
  <TextField key="callingCode" source="callingCode" />,
  <TextField key="tel" source="tel" />,
  <TextField key="name" source="name" />,
  <TextField key="gender" source="gender" />,
  <EmailField key="email" source="email" />,
  <BooleanField
    key="receivePromotionalMessage"
    source="receivePromotionalMessage"
  />,
  <FunctionField
    key="ageRange"
    label="resources.customers.fields.ageRange"
    render={(record: any) =>
      ageRangeOptions.find((a) => a.id === record.ageRange)?.name
    }
  />,
  // <TextField key="birthYear" source="birthYear" />,
  <TextField key="birthMonth" source="birthMonth" />,
  <BooleanField key="isEnabled" source="isEnabled" />,
  <FunctionField
    key="tier.name.default.f"
    label="resources.customers.fields.tier"
    sortBy="tier.id"
    render={(record: any) =>
      `${record?.tier?.tierGroup?.name || ""} ${record?.tier?.name?.default}`
    }
  />,
  <DateField key="createdAt" source="createdAt" showTime={true} />,
];

export default CustomerFields;
