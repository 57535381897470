import { parse } from "query-string";
import { FC } from "react";
import {
  Create,
  CreateProps,
  maxValue,
  minValue,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput
} from "react-admin";
import { Coupon, Customer } from "../shared/types";

const ManualCouponCreate: FC<CreateProps> = (props) => {
  const { "customer.id": customerId } = parse(props.location?.search || "");
  const redirect = customerId
    ? `/customers/${customerId}/couponInstances`
    : `/manualCoupons`;
  return (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput
          source="customerId"
          reference="customers"
          validate={required()}
          initialValue={customerId}
        >
          <SelectInput
            optionText={(customer: Customer) =>
              `+${customer.callingCode} ${customer.tel}`
            }
            optionValue="id"
          />
        </ReferenceInput>
        <ReferenceInput
          source="couponId"
          reference="coupons"
          validate={required()}
          perPage={200}
        >
          <SelectInput
            optionText={(coupon: Coupon) => coupon.name.default}
            optionValue="id"
          />
        </ReferenceInput>
        <NumberInput
          source="quantity"
          defaultValue={1}
          validate={[minValue(1), maxValue(100)]}
        />
        <TextInput multiline source="remark" />
        <SelectInput
          source="reason"
          choices={[
            {
              id: "VIP",
              name: "resources.manualCredits.reasons.vip",
            },
            {
              id: "Compliment",
              name: "resources.manualCredits.reasons.compliment",
            },
            {
              id: "Complain",
              name: "resources.manualCredits.reasons.complain",
            },
            {
              id: "Compensation",
              name: "resources.manualCredits.reasons.compensation",
            },
          ]}
        />
        <ReferenceInput source="outlet.id" reference="outlets">
          <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default ManualCouponCreate;
