import { Reducer } from "react";
import { BackendConfigs } from "../shared/types";
import { setBackendConfigs, SET_BACKEND_CONFIGS } from "./actions";

const backendConfigsReducer: Reducer<
  BackendConfigs,
  ReturnType<typeof setBackendConfigs>
> = (previousState = { version: "" }, action) => {
  if (action.type === SET_BACKEND_CONFIGS) {
    return action.payload;
  }
  return previousState;
};

export default backendConfigsReducer;
