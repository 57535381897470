import React, { FC } from "react";
import {
  BooleanInput,
  DateTimeInput,
  Edit,
  EditProps,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
} from "react-admin";
import { isBefore } from "../shared/validators";

const SpendingRewardProgramScheduleEdit: FC<EditProps> = (props) => {
  const redirect = (
    basePath: any,
    id: any,
    data: { spendingRewardProgram: { id: string } }
  ) => `/spendingRewardPrograms/${data.spendingRewardProgram.id}/schedules`;
  return (
    <Edit {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput
          disabled
          source="spendingRewardProgram.id"
          reference="spendingRewardPrograms"
          validate={required()}
        >
          <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <DateTimeInput
          source="beginTime"
          validate={[required(), isBefore("endTime", "End Time")]}
        />
        <DateTimeInput source="endTime" validate={required()} />
        <BooleanInput source="isEnabled" validate={required()} />
      </SimpleForm>
    </Edit>
  );
};

export default SpendingRewardProgramScheduleEdit;
