import React, { FC } from "react";
import {
  DateTimeInput,
  Edit,
  EditProps,
  required,
  SimpleForm,
  TextInput,
} from "react-admin";
const TierGroupEdit: FC<EditProps> = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <DateTimeInput source="effectiveTime" validate={required()} />
    </SimpleForm>
  </Edit>
);
export default TierGroupEdit;
