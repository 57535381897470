import React, { FC } from "react";
import {
  AutocompleteInput,
  Datagrid,
  Filter,
  FilterProps,
  List,
  ListProps,
  ReferenceInput,
} from "react-admin";
import CustomerReferenceField from "../shared/components/CustomerReferenceField";
import { CouponInstance } from "../shared/types";
import ManualCouponFields from "./ManualCouponFields";

const ManualCouponFilter: FC<Omit<FilterProps, "children">> = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput source="couponInstance.id" reference="couponInstances">
        <AutocompleteInput
          optionText={(couponInstance: CouponInstance) =>
            couponInstance?.id?.toString()
          }
        />
      </ReferenceInput>
    </Filter>
  );
};

const ManualCouponList: FC<ListProps> = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    sort={{ field: "couponInstance.issueTime", order: "DESC" }}
    filters={<ManualCouponFilter />}
  >
    <Datagrid rowClick="show">
      <CustomerReferenceField
        source="couponInstance.customer.id"
        label="resources.customers.fields.tel"
      />
      ,{ManualCouponFields}
    </Datagrid>
  </List>
);

export default ManualCouponList;
