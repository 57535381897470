import AsyncLock from "async-lock";
import { AuthProvider } from "react-admin";
import { LOGIN, LOGOUT, REFRESH_TOKEN } from "../shared/api-path";
import { authStorageKey } from "../shared/constants/constant-values";
import { basicFetch } from "../shared/http-base";

const checkAuthLock = new AsyncLock();

export const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const response = await basicFetch(LOGIN, {
      method: "POST",
      body: JSON.stringify({ username, password }),
    });
    await storeAuthPayload(response);
  },
  logout: async () => {
    try {
      await basicFetch(LOGOUT, {
        method: "POST",
      });
    } catch {
      // Just bypass the error, let the user logout.
    } finally {
      localStorage.removeItem(authStorageKey);
    }
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    // Use async lock to avoid calling refresh token simultaneously which cause auth exception
    return checkAuthLock.acquire("checkAuth", async function () {
      const authPayload: string | null = localStorage.getItem(authStorageKey);
      if (!authPayload) {
        throw new Error("No auth information found.");
      }
      const { accessToken, refreshToken } = JSON.parse(authPayload);
      const response = await basicFetch(REFRESH_TOKEN, {
        method: "POST",
        body: JSON.stringify({ accessToken, refreshToken }),
      });
      await storeAuthPayload(response);
    });
  },
  getPermissions: () => {
    const authPayload: string | null = localStorage.getItem(authStorageKey);
    if (authPayload) {
      const { roles } = JSON.parse(authPayload);
      return Promise.resolve(roles);
    }
    return Promise.resolve([]);
  },
  getIdentity: () => {
    const authPayload: string | null = localStorage.getItem(authStorageKey);
    if (authPayload) {
      const { username } = JSON.parse(authPayload);
      return Promise.resolve({
        id: username,
        fullName: username,
      });
    } else {
      return Promise.resolve({
        id: "N/A",
      });
    }
  },
};

async function storeAuthPayload(response: Response) {
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  const responseBody = await response.json();
  if (!responseBody.roles.includes("admin")) {
    throw new Error("Your account does not have admin role");
  }
  localStorage.setItem(authStorageKey, JSON.stringify(responseBody));
}
