import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TierCreate from "./TierCreate";
import TierEdit from "./TierEdit";
import TierList from "./TierList";

const exported = {
  list: TierList,
  create: TierCreate,
  edit: TierEdit,
  icon: TrendingUpIcon,
};
export default exported;
