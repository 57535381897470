import { FC } from "react";
import {
  BooleanField,
  Datagrid,
  FunctionField,
  List,
  ListProps,
  TextField,
} from "react-admin";

const FranchiseStoreList: FC<ListProps> = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <BooleanField source="isEnabled" />
      <TextField source="businessRegistrationNumber" />
      <TextField source="address" />
      <TextField source="remark" />
      <FunctionField
        label="resources.franchiseStores.fields.managementMembersCount"
        render={(record: any) => record?.managementMembers?.length ?? 0}
      />
    </Datagrid>
  </List>
);

export default FranchiseStoreList;
