import React, { FC } from "react";
import { required, SelectInput } from "react-admin";
import { CustomerRewardProgramType } from "../shared/constants/customer-reward-program-type.enum";
import { PeriodicInterval } from "../shared/constants/periodic-interval.enum";

interface Props {
  type: CustomerRewardProgramType;
}

const ConfigFields: FC<Props> = ({ type, ...rest }) => {
  switch (type) {
    case CustomerRewardProgramType.Birthday:
      return <></>;
    case CustomerRewardProgramType.Periodic:
      return (
        <SelectInput
          source="config.periodicInterval"
          validate={required()}
          translateChoice={true}
          choices={Object.values(PeriodicInterval).map((value) => {
            return {
              id: value,
              name: `resources.customerRewardPrograms.fieldGroups.${value}`,
            };
          })}
          {...rest}
        />
      );
    default:
      return <></>;
  }
};

export default ConfigFields;
