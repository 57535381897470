import React, { FC } from "react";
import {
  Edit,
  EditProps,
  minValue,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
} from "react-admin";

const CreditEarningThresholdMappingEdit: FC<EditProps> = (props) => {
  const redirect = (basePath: any, id: any, data: { tier: { id: string } }) =>
    `/tiers/${data.tier.id}/creditEarningThresholds`;
  return (
    <Edit {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput
          source="tier.id"
          reference="tiers"
          validate={required()}
        >
          <SelectInput
            optionText="name.default"
            optionValue="id"
            disabled={true}
          />
        </ReferenceInput>
        <ReferenceInput
          source="brand.id"
          reference="brands"
          validate={required()}
        >
          <SelectInput
            optionText="name.default"
            optionValue="id"
            disabled={true}
          />
        </ReferenceInput>
        <NumberInput
          source="creditEarningThreshold"
          validate={[required(), minValue(1)]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default CreditEarningThresholdMappingEdit;
