import { makeStyles } from "@material-ui/styles";
import { FC } from "react";
import {
  minValue,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator
} from "react-admin";
import { Coupon } from "../shared/types";

const useStyles = makeStyles(() => ({
  couponInputGroup: {
    "& li div.ra-input": {
      display: "inline-block",
      marginRight: 10,
    },
  },
}));

const CouponInput: FC<any> = (props) => {
  const classes = useStyles();
  return (
    <SimpleFormIterator
      margin="dense"
      className={classes.couponInputGroup}
      {...props}
    >
      <ReferenceInput
        source="coupon.id"
        reference="coupons"
        validate={required()}
        perPage={200}
      >
        <SelectInput
          optionText={(coupon: Coupon) => coupon.name.default}
          optionValue="id"
        />
      </ReferenceInput>
      <NumberInput
        source="quantity"
        defaultValue={1}
        validate={(required(), minValue(1))}
      />
    </SimpleFormIterator>
  );
};

export default CouponInput;
