import { FC } from "react";
import {
  DateField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField
} from "react-admin";
import CustomerReferenceField from "../shared/components/CustomerReferenceField";

export const ManualCreditShow: FC<ShowProps> = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <CustomerReferenceField
        addLabel={true}
        label="resources.customers.fields.tel"
        source="credit.customer.id"
      />
      <DateField showTime={true} source="credit.time" />
      <TextField source="remark" />
      <TextField source="reason" />
      <TextField source="outlet.name" />
      <TextField source="credit.amount" />
      <TextField source="credit.operateUser" />
    </SimpleShowLayout>
  </Show>
);
