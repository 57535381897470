import MoneyIcon from "@material-ui/icons/Money";
import SpendingRewardProgramCreate from "./SpendingRewardProgramCreate";
import SpendingRewardProgramEdit from "./SpendingRewardProgramEdit";
import SpendingRewardProgramList from "./SpendingRewardProgramList";

const exported = {
  list: SpendingRewardProgramList,
  edit: SpendingRewardProgramEdit,
  create: SpendingRewardProgramCreate,
  icon: MoneyIcon,
};

export default exported;
