import { FC } from "react";
import {
  ArrayField,
  Datagrid,
  DateField,
  NumberField,
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
} from "react-admin";

export const CustomerGroupActionShow: FC<ShowProps> = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="customerGroup.name" />
        <DateField source="time" showTime={true} />
        <TextField source="remark" />
        <TextField source="operateUser" />
        <NumberField source="creditAmount" />
        <ArrayField source="issuedCoupons">
          <Datagrid>
            <ReferenceField source="couponId" reference="coupons">
              <TextField source="id" />
            </ReferenceField>
            <NumberField source="quantity" />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};
