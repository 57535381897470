import ManualCreditCreate from "./ManualCreditCreate";
import ManualCreditList from "./ManualCreditList";
import { ManualCreditShow } from "./ManualCreditShow";

const exported = {
  list: ManualCreditList,
  show: ManualCreditShow,
  create: ManualCreditCreate,
};

export default exported;
