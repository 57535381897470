import ManualCreditConsumptionCredit from "./ManualCreditConsumptionCreate";
import ManualCreditConsumptionList from "./ManualCreditConsumptionList";
import { ManualCreditConsumptionShow } from "./ManualCreditConsumptionShow";

const exported = {
  list: ManualCreditConsumptionList,
  show: ManualCreditConsumptionShow,
  create: ManualCreditConsumptionCredit,
};

export default exported;
