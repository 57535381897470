import { FC } from "react";
import { minValue, NumberInput, required } from "react-admin";
import { CouponType } from "../shared/constants/coupon-type.enum";

interface Props {
  type: CouponType;
}

const ConfigFields: FC<Props> = ({ type, ...rest }) => {
  switch (type) {
    case CouponType.Cash:
      return (
        <NumberInput
          source="config.amount"
          validate={[required(), minValue(1)]}
          min={1}
          {...rest}
        />
      );
    default:
      return <></>;
  }
};

export default ConfigFields;
