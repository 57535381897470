import React, { FC } from "react";
import { Datagrid, DateField, List, ListProps, TextField } from "react-admin";

const TierGroupList: FC<ListProps> = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <DateField source="effectiveTime" />
    </Datagrid>
  </List>
);

export default TierGroupList;
