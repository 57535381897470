import { FC } from "react";
import {
  Edit,
  EditProps,
  SimpleForm,
  TextInput
} from "react-admin";

export const SystemConfigurationEdit: FC<EditProps> = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id"  disabled={true}  />
      <TextInput source="value" />
    </SimpleForm>
  </Edit>
);
