import React, { FC } from "react";
import {
  Datagrid,
  DateField,
  DateInput,
  DateTimeInput,
  Edit,
  EditProps,
  FormTab,
  ImageField,
  NumberInput,
  Pagination,
  ReferenceInput,
  ReferenceManyField,
  required,
  SaveButton,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
} from "react-admin";
import { GroupBuyingEventStatus } from "../shared/constants/group-buying-event-status.enum";
import { AddNewPickUpPointButton } from "./AddNewPickUpPointButton";

// A custom toolbar to remove the delete button
const CustomToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const GroupBuyingEventEdit: FC<EditProps> = (props) => (
  <Edit {...props}>
    <TabbedForm toolbar={<CustomToolbar />}>
      <FormTab label="resources.groupBuyingEvents.fieldGroups.event">
        <ReferenceInput
          source="groupBuyingGroup.id"
          reference="groupBuyingGroups"
          validate={required()}
        >
          <SelectInput disabled={true} optionValue="id" optionText="name" />
        </ReferenceInput>
        <TextInput source="name" validate={required()} />
        <ReferenceInput
          source="outlet.id"
          reference="outlets"
          validate={required()}
        >
          <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <SelectInput
          source="status"
          validate={required()}
          multiple={false}
          choices={Object.values(GroupBuyingEventStatus).map((value) => {
            return {
              id: value,
              name: value,
            };
          })}
        />
        <ReferenceInput
          source="groupBuyingDistrict.id"
          reference="groupBuyingDistricts"
          validate={required()}
        >
          <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <DateTimeInput source="launchTime" validate={required()} />
        <DateInput source="deliveryDate" validate={required()} />
        <NumberInput source="minAmount" validate={required()} />
        <TextInput source="driverLicensePlate" />
        <TextInput source="driverMobile" />
        <TextInput source="remark" multiline />
      </FormTab>
      <FormTab
        label="resources.groupBuyingEvents.fieldGroups.pickUpPoints"
        path="pickUpPoint"
      >
        <ReferenceManyField
          sort={{ field: "time", order: "ASC" }}
          label=""
          reference="groupBuyingPickUpPoints"
          target="groupBuyingEvent.id"
          perPage={5}
          pagination={<Pagination />}
        >
          <Datagrid
            rowClick={(id, basePath, record) =>
              `/groupBuyingPickUpPoints/${id}?groupBuyingEvent.id=${props.id}`
            }
          >
            <TextField source="name" />
            <DateField showTime={true} source="time" />
            <ImageField source="imageUrl" />
            <TextField source="inChargePersonName" />
            <TextField source="inChargePersonMobile" />
          </Datagrid>
        </ReferenceManyField>
        <AddNewPickUpPointButton />
      </FormTab>
    </TabbedForm>
  </Edit>
);
