import CustomerGroupCreate from "./CustomerGroupCreate";
import CustomerGroupEdit from "./CustomerGroupEdit";
import CustomerGroupList from "./CustomerGroupList";

const exported = {
  list: CustomerGroupList,
  edit: CustomerGroupEdit,
  create: CustomerGroupCreate,
};

export default exported;
