import { fetchUtils } from "ra-core";
import { authStorageKey } from "./constants/constant-values";

export function basicFetch(path: string, init?: RequestInit) {
  return fetch(process.env.REACT_APP_BACKEND_URL + path, updateInit(init));
}

function updateInit(init: RequestInit = {}): RequestInit {
  const updatedInit: RequestInit = { ...init };
  if (!updatedInit.headers) {
    updatedInit.headers = new Headers({ "Content-Type": "application/json" });
  }
  const authPayload: string | null = localStorage.getItem(authStorageKey);
  if (authPayload) {
    const { accessToken } = JSON.parse(authPayload);
    (updatedInit.headers as Headers).set(
      "Authorization",
      `Bearer ${accessToken}`
    );
  }
  return updatedInit;
}

export const basicHttpClient = (url: any, options: fetchUtils.Options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const authPayload: string | null = localStorage.getItem(authStorageKey);
  if (authPayload) {
    const { accessToken } = JSON.parse(authPayload);
    (options.headers as Headers).set("Authorization", `Bearer ${accessToken}`);
  }
  return fetchUtils.fetchJson(url, options);
};
