import React, { FC } from "react";
import {
  AutocompleteInput,
  Datagrid,
  Filter,
  FilterProps,
  List,
  ListProps,
  ReferenceInput,
} from "react-admin";
import CouponReferenceField from "../shared/components/CouponReferenceField";
import CustomerReferenceField from "../shared/components/CustomerReferenceField";
import { Credit, CreditConsumption, Customer } from "../shared/types";
import TransactionFields from "./TransactionFields";

const TransactionFilter: FC<Omit<FilterProps, "children">> = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput
        label="resources.transactions.fields.customerTel"
        source="customer.id"
        reference="customers"
      >
        <AutocompleteInput
          optionText={(customer: Customer) =>
            customer.callingCode + customer.tel
          }
        />
      </ReferenceInput>
      <ReferenceInput source="credit.id" reference="credits">
        <AutocompleteInput
          optionText={(credit: Credit) => credit?.id?.toString()}
        />
      </ReferenceInput>
      <ReferenceInput
        source="creditConsumption.id"
        reference="creditConsumptions"
      >
        <AutocompleteInput
          optionText={(choice: CreditConsumption) => choice?.id?.toString()}
        />
      </ReferenceInput>
    </Filter>
  );
};

export const TransactionList: FC<ListProps> = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<TransactionFilter />}
    sort={{ field: "time", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <CustomerReferenceField
        source="customer.id"
        label="resources.transactions.fields.customerTel"
      />
      {TransactionFields}
      <CouponReferenceField
        label="resources.transactions.fields.redeemedCoupon"
        source="couponInstance.coupon.id"
      />
    </Datagrid>
  </List>
);
