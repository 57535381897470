import React, { FC } from "react";
import { Show, ShowProps, SimpleShowLayout, TextField } from "react-admin";
import CouponReferenceField from "../shared/components/CouponReferenceField";
import CustomerReferenceField from "../shared/components/CustomerReferenceField";
import TransactionFields from "./TransactionFields";

export const TransactionShow: FC<ShowProps> = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <CustomerReferenceField
        addLabel={true}
        label="resources.transactions.fields.customerTel"
        source="customer.id"
      />
      {TransactionFields}
      <CouponReferenceField
        addLabel={true}
        label="resources.transactions.fields.redeemedCoupon"
        source="couponInstance.coupon.id"
      />
      <TextField source="refId" />
      <TextField source="refApp" />
    </SimpleShowLayout>
  </Show>
);
