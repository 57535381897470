import WelcomeOfferProgramCreate from "./WelcomeOfferProgramCreate";
import WelcomeOfferProgramEdit from "./WelcomeOfferProgramEdit";
import WelcomeOfferProgramList from "./WelcomeOfferProgramList";

const exported = {
  list: WelcomeOfferProgramList,
  edit: WelcomeOfferProgramEdit,
  create: WelcomeOfferProgramCreate,
};

export default exported;
