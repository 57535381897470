import { parse } from "query-string";
import { FC } from "react";
import {
  Create,
  CreateProps,
  minValue,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
} from "react-admin";

const CreditEarningThresholdMappingCreate: FC<CreateProps> = (props) => {
  const { "tier.id": tierId } = parse(props.location?.search || "");
  const redirect = tierId ? `/tiers/${tierId}/creditEarningThresholds` : false;
  return (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput
          source="tier.id"
          reference="tiers"
          validate={required()}
          initialValue={tierId}
        >
          <SelectInput
            optionText="name.default"
            optionValue="id"
            disabled={true}
          />
        </ReferenceInput>
        <ReferenceInput
          source="brand.id"
          reference="brands"
          validate={required()}
        >
          <SelectInput optionText="name.default" optionValue="id" />
        </ReferenceInput>
        <NumberInput
          source="creditEarningThreshold"
          validate={[required(), minValue(1)]}
        />
      </SimpleForm>
    </Create>
  );
};

export default CreditEarningThresholdMappingCreate;
