import ScheduleIcon from "@material-ui/icons/Schedule";
import React, { FC } from "react";
import { Button, Record } from "react-admin";
import { Link } from "react-router-dom";

interface AddNewScheduleButtonProps {
  record?: Record;
}

const AddNewScheduleButton: FC<AddNewScheduleButtonProps> = ({ record }) => (
  <Button
    component={Link}
    to={`/spendingRewardProgramSchedules/create?spendingRewardProgram.id=${record?.id}`}
    label="resources.spendingRewardPrograms.fieldGroups.creditSchedule"
  >
    <ScheduleIcon />
  </Button>
);

export default AddNewScheduleButton;
