import React, { FC } from "react";
import { ReferenceField, TextField } from "react-admin";

interface GroupBuyingGroupReferenceFieldProps {
  addLabel?: boolean;
  source: string;
  label: string;
}

const GroupBuyingGroupReferenceField: FC<
  GroupBuyingGroupReferenceFieldProps
> = (props) => (
  <ReferenceField {...props} reference="groupBuyingGroups">
    <TextField source="name" />
  </ReferenceField>
);

export default GroupBuyingGroupReferenceField;
