import { FC } from "react";
import {
  ArrayInput,
  CheckboxGroupInput,
  Create,
  CreateProps,
  DateInput,
  DateTimeInput,
  FormDataConsumer,
  minValue,
  NumberInput,
  ReferenceArrayInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput
} from "react-admin";
import MultiLanguageInput from "../shared/components/MultiLanguageInput";
import TimeInput from "../shared/components/TimeInput";
import { CouponExpiryType } from "../shared/constants/coupon-expiry-type.enum";
import { CouponType } from "../shared/constants/coupon-type.enum";
import { Weekdays } from "../shared/types";
import ConfigFields from "./ConfigsFields";

const CouponCreate: FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        {MultiLanguageInput({
          label: "resources.coupons.fields.name",
          source: "name",
          validate: required(),
        })}
        {MultiLanguageInput({
          label: "resources.coupons.fields.description",
          source: "description",
        })}
        <TextInput source="remark" multiline />
        <DateTimeInput source="validFrom" />
        <SelectInput
          validate={required()}
          source="expiryType"
          choices={Object.values(CouponExpiryType).map((value) => {
            return {
              id: value,
              name: value,
            };
          })}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            switch (formData.expiryType) {
              case CouponExpiryType.SpecificDate:
                return (
                  <DateTimeInput
                    source="expiryDate"
                    validate={[required()]}
                    helperText="resources.coupons.helpers.expiryDate"
                    {...rest}
                  />
                );
              case CouponExpiryType.ValidTime:
                return (
                  <NumberInput
                    min={1}
                    source="validDaysAfterIssue"
                    validate={[required(), minValue(1)]}
                    {...rest}
                  />
                );
              default:
                return <></>;
            }
          }}
        </FormDataConsumer>
        <SelectInput
          validate={required()}
          source="type"
          choices={Object.values(CouponType).map((value) => {
            return {
              id: value,
              name: value,
            };
          })}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <ConfigFields type={formData.type} {...rest} />
          )}
        </FormDataConsumer>
        <CheckboxGroupInput
          source="usePeriodWeekdays"
          parse={(input: string[]) => {
            return input?.reduce((acc: { [key: string]: boolean }, cur) => {
              acc[cur] = true;
              return acc;
            }, {});
          }}
          format={(input: Weekdays) => {
            if (!input) {
              return [];
            }
            const result: string[] = [];
            for (const [key, value] of Object.entries(input)) {
              if (value === true) {
                result.push(key);
              }
            }
            return result;
          }}
          optionText="weekday"
          optionValue="weekday"
          choices={[
            { weekday: "sunday" },
            { weekday: "monday" },
            { weekday: "tuesday" },
            { weekday: "wednesday" },
            { weekday: "thursday" },
            { weekday: "friday" },
            { weekday: "saturday" },
          ]}
        />
        <ArrayInput source="usePeriodTimeRanges">
          <SimpleFormIterator>
            <TimeInput
              label="resources.coupons.fields.from"
              source="from"
              validate={required()}
            />
            <TimeInput
              label="resources.coupons.fields.to"
              source="to"
              validate={required()}
            />
          </SimpleFormIterator>
        </ArrayInput>
        <ReferenceArrayInput
          source="useOutlets"
          reference="outlets"
          format={(outlets: any[]) => outlets && outlets.map((o: any) => o.id)}
          parse={(ids: string[]) => ids && ids.map((id: string) => ({ id }))}
        >
          <SelectArrayInput optionText="name" optionValue="id" />
        </ReferenceArrayInput>
        <NumberInput source="minSpend" min={0} validate={minValue(0)} />
      </SimpleForm>
    </Create>
  );
};

export default CouponCreate;
