import LocalActivity from "@material-ui/icons/LocalActivity";
import React, { FC } from "react";
import { Button, Record } from "react-admin";
import { Link } from "react-router-dom";

interface AddNewManualCouponButtonProps {
  record?: Record;
}

const AddNewManualCouponButton: FC<AddNewManualCouponButtonProps> = ({
  record,
}) => (
  <Button
    component={Link}
    to={`/manualCoupons/create?customer.id=${record?.id}`}
    label="resources.customers.fields.createNewManualCoupon"
  >
    <LocalActivity />
  </Button>
);
export default AddNewManualCouponButton;
