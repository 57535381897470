import React, { FC } from "react";
import {
  ArrayInput,
  BooleanInput,
  Datagrid,
  DateField,
  Edit,
  EditProps,
  FormTab,
  NumberField,
  Pagination,
  ReferenceManyField,
  required,
  TabbedForm,
} from "react-admin";
import CustomerReferenceField from "../shared/components/CustomerReferenceField";
import Inputs from "./Inputs";
import TelsInput from "./TelsInput";

const WelcomeOfferProgramEdit: FC<EditProps> = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="resources.welcomeOfferPrograms.fieldGroups.config">
          {Inputs}
          <BooleanInput source="isPermanent" validate={required()} />
          <BooleanInput source="isEnabled" validate={required()} />
          <BooleanInput source="isLimitToTelOnly" validate={required()} />
          <ArrayInput source="welcomeOfferProgramTels">
            <TelsInput />
          </ArrayInput>
        </FormTab>
        <FormTab
          label="resources.welcomeOfferPrograms.fieldGroups.records"
          path="records"
        >
          <ReferenceManyField
            sort={{ field: "credit.time", order: "DESC" }}
            label=""
            reference="welcomeOfferRecords"
            target="welcomeOfferProgram.id"
            perPage={5}
            pagination={<Pagination />}
          >
            <Datagrid>
              <DateField
                label="resources.welcomeOfferPrograms.fields.time"
                source="credit.time"
                showTime={true}
              />
              <NumberField
                label="resources.welcomeOfferPrograms.fields.amount"
                source="credit.amount"
              />
              <CustomerReferenceField
                addLabel={true}
                label="resources.welcomeOfferPrograms.fields.customerTel"
                source="credit.customer.id"
              />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default WelcomeOfferProgramEdit;
