import { FC } from "react";
import {
  ArrayInput,
  BooleanInput,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  EditProps,
  FormTab,
  ImageInput,
  Pagination,
  ReferenceManyField,
  required,
  TabbedForm,
  TextInput
} from "react-admin";
import CouponReferenceField from "../shared/components/CouponReferenceField";
import CustomerReferenceField from "../shared/components/CustomerReferenceField";
import MultiLanguageInput from "../shared/components/MultiLanguageInput";
import PreviewImage from "../shared/components/PreviewImage";
import { uploadFileToAwsS3 } from "../shared/helpers";
import CouponInput from "./CouponInput";

export const CouponPromotionEdit: FC<EditProps> = (props) => {
  const transform = async (data: any) => {
    if (!data.imageUrl) {
      return data;
    }
    // If the image is a string, it means that the image has not been changed
    // Otherwise, the image has been changed, we need to upload the image to AWS S3
    if (typeof data.imageUrl !== 'string'){
      const imageUrl = await uploadFileToAwsS3(data.imageUrl.rawFile);
      data.imageUrl = imageUrl;
    }
    return data;
  };

  return (
    <Edit {...props} transform={transform}>
      <TabbedForm>
        <FormTab label="resources.couponPromotions.fieldGroups.config">
          {MultiLanguageInput({
            label: "resources.couponPromotions.fields.name",
            source: "name",
            validate: required(),
          })}
          <TextInput source="remark" multiline />
          <ArrayInput validate={required()} source="couponPromotionCoupons">
            <CouponInput />
          </ArrayInput>
          <DateTimeInput source="beginTime" validate={required()} />
          <DateTimeInput source="endTime" validate={required()} />
          <BooleanInput source="isEnabled" validate={required()} />
          <ImageInput
            source="imageUrl"
            accept="image/*
        "
          >
            <PreviewImage source="src" />
          </ImageInput>
        </FormTab>
        <FormTab
          label="resources.couponPromotions.fieldGroups.records"
          path="records"
        >
          <ReferenceManyField
            sort={{ field: "couponInstance.issueTime", order: "DESC" }}
            label=""
            reference="couponPromotionRecords"
            target="couponPromotion.id"
            perPage={5}
            pagination={<Pagination />}
          >
            <Datagrid>
              <DateField
                label="resources.couponPromotions.fields.couponInstance.issueTime"
                source="couponInstance.issueTime"
                showTime={true}
              />
              <CouponReferenceField
                label="resources.couponPromotions.fields.coupon.name"
                source="couponInstance.coupon.id"
              />
              <CustomerReferenceField
                label="resources.couponPromotions.fields.customer"
                source="customer.id"
              />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
