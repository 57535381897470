import { basicFetch } from "../shared/http-base";
import { GET_S3_PUT_OBJECT_SIGNED_URL } from "./api-path";

export const uploadFileToAwsS3 = async (file: File): Promise<string | null> => {
  const s3SignedUrlResponse = await basicFetch(
    GET_S3_PUT_OBJECT_SIGNED_URL + file.name,
    { method: "GET" }
  );
  const s3SignedUrl = await s3SignedUrlResponse.text();
  await fetch(s3SignedUrl, { method: "PUT", body: file });
  return s3SignedUrl.split("?")[0];
};
