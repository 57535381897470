import {
  DateInput,
  maxLength,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
} from "react-admin";
import { Coupon } from "../shared/types";

const Inputs = [
  <TextInput source="name" validate={[required(), maxLength(100)]} />,
  <NumberInput source="amount" validate={required()} />,
  <ReferenceInput
    label="resources.customerRewardPrograms.fields.coupon"
    source="coupon.id"
    reference="coupons"
    perPage={200}
    allowEmpty
  >
    <SelectInput optionText={(coupon: Coupon) => coupon.name.default} />
  </ReferenceInput>,
  <DateInput key="beginTime" source="beginTime" />,
  <DateInput key="endTime" source="endTime" />,
];

export default Inputs;
