import { FC } from "react";
import {
  Datagrid,
  DeleteButton,
  Edit,
  EditProps,
  FormTab,
  minValue,
  NumberField,
  NumberInput,
  ReferenceInput,
  ReferenceManyField,
  required,
  SelectInput,
  TabbedForm,
  TextField,
} from "react-admin";
import MultiLanguageInput from "../shared/components/MultiLanguageInput";
import ReactColorInput from "../shared/components/ReactColorInput";
import AddNewCreditEarningThresholdButton from "./AddNewCreditEarningThresholdButton";

const TierEdit: FC<EditProps> = (props) => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label={"resources.tiers.name"}>
        <ReferenceInput
          label="resources.tierGroups.name"
          source="tierGroup.id"
          reference="tierGroups"
          allowEmpty
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        {MultiLanguageInput({
          label: "resources.tiers.fields.name",
          source: "name",
          validate: required(),
        })}
        <ReactColorInput source="colorCode" validate={required()} />
        <NumberInput
          source="creditEarningThreshold"
          validate={[required(), minValue(1)]}
        />
        <NumberInput
          source="spendingThreshold"
          validate={[required(), minValue(1)]}
        />
        <NumberInput
          source="creditExpiryDays"
          validate={[required(), minValue(1)]}
        />
      </FormTab>
      <FormTab
        label={"resources.tiers.creditEarningThresholds"}
        path="creditEarningThresholds"
      >
        <ReferenceManyField
          label=""
          reference="creditEarningThresholdMappings"
          target="tier.id"
        >
          <Datagrid rowClick="edit">
            <TextField
              source="brand.name.default"
              label="resources.tiers.fields.brand"
            />
            <NumberField
              source="creditEarningThreshold"
              options={{
                minimumFractionDigits: 2,
              }}
            />
            <DeleteButton redirect={false} />
          </Datagrid>
        </ReferenceManyField>
        <AddNewCreditEarningThresholdButton />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default TierEdit;
