import { FC } from "react";
import {
  ArrayInput,
  BooleanInput,
  Create,
  CreateProps,
  DateTimeInput,
  ImageField,
  ImageInput,
  required,
  SimpleForm,
  TextInput
} from "react-admin";
import MultiLanguageInput from "../shared/components/MultiLanguageInput";
import { uploadFileToAwsS3 } from "../shared/helpers";
import CouponInput from "./CouponInput";

export const CouponPromotionCreate: FC<CreateProps> = (props) => {
  const transform = async (data: any) => {
    if (!data.imageUrl) {
      return data;
    }
    const imageUrl = await uploadFileToAwsS3(data.imageUrl.rawFile);
    data.imageUrl = imageUrl;
    return data;
  };
  return (
    <Create {...props} transform={transform}>
      <SimpleForm>
        {MultiLanguageInput({
          label: "resources.coupons.fields.name",
          source: "name",
          validate: required(),
        })}
        <TextInput source="remark" multiline />
        <ArrayInput validate={required()} source="couponPromotionCoupons">
          <CouponInput />
        </ArrayInput>
        <DateTimeInput source="beginTime" validate={required()} />
        <DateTimeInput source="endTime" validate={required()} />
        <BooleanInput
          source="isEnabled"
          validate={required()}
          initialValue={true}
        />
        <ImageInput source="imageUrl" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};
