import SettingsIcon from "@material-ui/icons/Settings";
import { SystemConfigurationEdit } from "./SystemConfigurationEdit";
import { SystemConfigurationList } from "./SystemConfigurationList";

const exported = {
  list: SystemConfigurationList,
  edit: SystemConfigurationEdit,
  icon: SettingsIcon,
};

export default exported;
