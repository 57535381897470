import React, { FC } from "react";
import {
  Datagrid,
  Filter,
  FilterProps,
  List,
  ListProps,
  TextInput,
} from "react-admin";
import CustomerFields from "./CustomerFields";

const CustomerFilter: FC<Omit<FilterProps, "children">> = (props) => {
  return (
    <Filter {...props}>
      <TextInput source="callingCode" />
      <TextInput source="tel" />
      <TextInput source="name" />
    </Filter>
  );
};

const CustomerList: FC<ListProps> = (props) => (
  <List {...props} bulkActionButtons={false} filters={<CustomerFilter />}>
    <Datagrid rowClick="edit">{CustomerFields}</Datagrid>
  </List>
);

export default CustomerList;
